import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';

class CKEditornpage extends Component {

    constructor(props) {
        super(props);
        this.onChangeContentChange = this.onChangeContentChange.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
        this.afterPaste = this.afterPaste.bind(this);
        this.state = {
            content: '',
            image_url: "",
            image: ""
        }
    }


    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("full_screen_ad");
        console.log(result)
        if (result.result) {
            this.setState({ content: result.data.description_en })
        } else {
            this.toastFail(result.message);
        }
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    onChangeContentChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })

    }

    onChangeImage = async (event) =>  {
        console.log(event.target.files)
        var reader = new FileReader();
        this.state.image = event.target.files[0];
        
        if (!this.state.image) return this.toastFail("Please select Image!!!");

        let form = new FormData();

        form.append("image_url", this.state.image);

        let result = await API.uploadImage(form);

        if (result.result) {
            this.toastSuccess();
            this.setState({image_url: API.getImageURL(result.data)})
        } else {
            this.toastFail(result.message)
        }


    }

    toastSuccess(text) {
        toast.success(text || "Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    onSave = async () => {
        let data = {
            description_en: this.state.content,
            type: "full_screen_ad"
        }
        let result = await API.updateSetting(data);
        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }

    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }

    copyTextToClipboard() {
        if (!navigator.clipboard) {
            this.toastFail(" Please copy menual, your browser is not supported");
          return;
        }
        navigator.clipboard.writeText(this.state.image_url).then(() => {
            this.toastSuccess("Text Copied!");
        }, (err) => {
            this.toastFail("Fail To copy!, Please copy menual");
        });
      }


    render() {
        return (
            <div>
                <Breadcrumb title="AD POPUP" parent="Setting" />
                <ToastContainer />
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">

                            
                                <div className="card-header">
                                    <h5>Description</h5>
                                </div>

                                <div className="card-body">
                                    <CKEditors
                                        activeClass="p10"
                                        content={this.state.content}
                                        events={{
                                            "blur": this.onBlur,
                                            "afterPaste": this.afterPaste,
                                            "change": this.onChangeContentChange
                                        }}
                                    />
                                    {/* <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button> */}
                                    <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button>

                                </div>

                                <div className="card-header">
                                    <h5>Image Upload</h5>
                                </div>

                                <div className="card-body">
                                    <input type="text" style={{width: 400}} value={this.state.image_url} ></input>
                                    <Button size="sm"  color="success" style={{ marginLeft: 10 }} onClick={this.copyTextToClipboard} className="btn-square active">Copy Link</Button><br/><br/>
                                    <input type="file" onChange={this.onChangeImage}></input><br/><br/>

                                    <img src={this.state.image_url} style={{ width: 600 }} className="img-thumbnail" alt="Cinque Terre"></img><br/>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}


export default CKEditornpage;
