import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';

class CKEditornpage extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.afterPaste = this.afterPaste.bind(this);
        this.state = {
            content: '',
            title: "",
            image_url: "",
        }
    }


    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("app_share");
        if (result.result) {
            this.setState({ content: result.data.description_en, title: result.data.title, image_url: result.data.image_url })
        } else {
            this.toastFail(result.message);
        }
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })

    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    onSave = async () => {
        let data = {
            description_en: this.state.content,
            title: this.state.title,
            image_url: this.state.image_url,
            type: "app_share"
        }
        let result = await API.updateSetting(data);
        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }

    onChangeImage = async (event) => {
        console.log(event.target.files)
        var reader = new FileReader();
        this.state.image = event.target.files[0];

        if (!this.state.image) return this.toastFail("Please select Image!!!");

        let form = new FormData();

        form.append("image_url", this.state.image);

        let result = await API.uploadImage(form);

        if (result.result) {
            this.toastSuccess();
            this.setState({ image_url: API.getImageURL(result.data) })
        } else {
            this.toastFail(result.message)
        }


    }

    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }

    render() {
        return (
            <div>
                <Breadcrumb title="App Share" parent="Setting" />
                <ToastContainer />
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">

                                <div className="card-header">
                                    <h5>Title</h5>
                                </div>
                                <div className="card-body">
                                    <Form className="theme-form">
                                        <FormGroup className="row">
                                            <Col sm="12">
                                                <Input
                                                    onChange={(e) => {
                                                        this.state.title = e.target.value
                                                        this.setState({})
                                                    }}
                                                    value={this.state.title}
                                                    style={{ color: "black" }}
                                                    className="form-control" type="text" placeholder="Title" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </div>

                                <div className="card-header">
                                    <h5>Description</h5>
                                </div>

                                <div className="card-body">
                                    <CKEditors
                                        activeClass="p10"
                                        content={this.state.content}
                                        events={{
                                            "blur": this.onBlur,
                                            "afterPaste": this.afterPaste,
                                            "change": this.onChange
                                        }}
                                    />
                                    {/* <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button> */}

                                </div>

                                <div className="card-header">
                                    <h5>Image Preview</h5>
                                </div>

                                <div className="card-body">
                                    <img src={this.state.image_url} style={{ width: 600 }} class="img-thumbnail" alt="Cinque Terre"></img><br />
                                    <input type="file" onChange={this.onChangeImage} ></input><br />
                                    <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}


export default CKEditornpage;
