import React, { Component } from 'react';
import { Button } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';

class CKEditornpage extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.afterPaste = this.afterPaste.bind(this);
        this.state = {
            content: '',
            image_url: "",
            image: ""
        }
    }

    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("app_share");
        console.log("result: " ,result)
        if (result.result) {
            this.setState({ image_url: API.getImageURL(result.data.image_url) })
        } else {
            this.toastFail(result.message);
        }
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    onChange(event) {
        console.log(event.target.files)
        var reader = new FileReader();
        this.state.image = event.target.files[0];
        var url = reader.readAsDataURL(this.state.image);

        reader.onloadend = function (e) {
        this.setState({
          image_url: [reader.result]
        })
        }.bind(this);
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    onSave = async () => {

        if (!this.state.image) return this.toastFail("Please select Image!!!");

        let form = new FormData();

        form.append("type", "banner_ad");
        form.append("image_url", this.state.image);

        let result = await API.updateSettingImage(form);

        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }


    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }

    render() {
        return (
            <div>
                <Breadcrumb title="Ad Banner " parent="Setting" />
                <ToastContainer />
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Ad Banner</h5>
                                </div>
                                <div className="card-body">
                                    <img src={this.state.image_url} style={{ width: 300, maxHeight: 500 }} className="img-thumbnail" alt="Cinque Terre"></img><br/>
                                    <input type="file" onChange={this.onChange}></input><br/>
                                    <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}


export default CKEditornpage;
