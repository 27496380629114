import { postRequest } from "./FetchPost";
import { getRequest } from "./FetchGet";
import { fileUpload } from "./FileUpload";

//local
// let serverUrl = "http://localhost:3000/";

// product
let serverUrl = "https://api.skypia.io/";

let apiVersion = serverUrl + "backend/";

let userRoute = apiVersion + "user/";
let categoryRoute = apiVersion + "category/";
let patternRoute = apiVersion + "pattern/";
let subcategoryRoute = apiVersion + "subcategory/";
let tagRoute = apiVersion + "tag/";
let postRoute = apiVersion + "post/";
let settingRoute = apiVersion + "setting/";
let changeLogRoute = apiVersion + "change_log/";
let postActionTypeRoute = apiVersion + "post_action_type/";
let reportTypeRoute = apiVersion + "report_type/";
let reportRoute = apiVersion + "report/";
let apkRoute = apiVersion + "apk/";
let adsRoute = apiVersion + "ads/";
let announcementRoute = apiVersion + "announcement/";
let onlineClassRegister = apiVersion + "online_class_register/";

let route = {
  // user & auth
  getDashboard: userRoute + "dashboard",
  login: userRoute + "email_login",
  getOneUser: userRoute + "get/one",
  getAllUsers: userRoute + "get/all",
  blockUser: userRoute + "block",
  getUserPosts: userRoute + "post/get",
  updateUser: userRoute + "update",

  //post
  getAllPosts: postRoute + "get/all",
  getOnePost: postRoute + "get/one",
  approvePost: postRoute + "approve",

  //pattern
  getAllPattern: patternRoute + "get/all",
  getOnePattern: patternRoute + "get/one",
  updatePattern: patternRoute + "update",

  //category
  getArtistCategory: categoryRoute + "get/artist",
  getAllCategory: categoryRoute + "get/all",
  getOneCategory: categoryRoute + "get/one",
  createCategory: categoryRoute + "create",
  updateCategory: categoryRoute + "update",
  updateCategoryOrder: categoryRoute + "order/update",

  //subcategory
  getCategory: categoryRoute + "get",
  getAllSubcategory: subcategoryRoute + "get/all",
  getOneSubcategory: subcategoryRoute + "get/one",
  createSubcategory: subcategoryRoute + "create",
  updateSubcategory: subcategoryRoute + "update",

  // other
  getSetting: settingRoute + "get",
  updateSetting: settingRoute + "update",
  updateSettingImage: settingRoute + "update/image",
  uploadImage: apiVersion + "image/upload",

  // online class member register
  getAllRegisterOnlineClassMember: onlineClassRegister + "get/all",
  getOneRegisterOnlineClassMember: onlineClassRegister + "get/one",
  updateRegisterOnlineClassMember: onlineClassRegister + "update",

  //Change Log
  getChangeLog: changeLogRoute + "get",
  createChangeLog: changeLogRoute + "create",

  //Post Action Type
  getPostActionType: postActionTypeRoute + "get",
  updatePostActionType: postActionTypeRoute + "update",

  //Report Type
  getAllReportType: reportTypeRoute + "get/all",
  getOneReportType: reportTypeRoute + "get/one",
  createReportType: reportTypeRoute + "create",
  updateReportType: reportTypeRoute + "update",

  //Report
  getAllReports: reportRoute + "get/all",
  getOneReport: reportRoute + "get/one",
  updateReportStatus: reportRoute + "status/update",

  // ads
  getAllAds: adsRoute + "get/all",
  getOneAds: adsRoute + "get/one",
  createAds: adsRoute + "create",
  updateAds: adsRoute + "update",
  deleteAds: adsRoute + 'delete',

   // announcement
   getAllAnnouncement: announcementRoute + "get/all",
   getOneAnnouncement: announcementRoute + "get/one",
   createAnnouncement: announcementRoute + "create",
   updateAnnouncement: announcementRoute + "update",
   deleteAnnouncement: announcementRoute + 'delete',

  //apk
  getApk: apkRoute + "get",
  uploadApk: apkRoute + "upload"
};

class API {
  serverUrl() {
    return serverUrl;
  }

  getParam(name) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(name);
}

  getImageURL(image_url) {

    if (!image_url) {
      image_url = ""
    }

    return image_url.includes("http") ? image_url : serverUrl + image_url;
  }

  postImageAndUser(results) {
    let data = results.data;
    data.forEach((result) => {
      result.image_preview = this.getImageURL(result.image_preview);
      result.user.profile_url = this.getImageURL(result.user.profile_url);

      result.data.forEach((d) => {
        if (d.file) {
          d.file = this.getImageURL(d.file);
        }
        if (d.voice) {
          d.voice = this.getImageURL(d.voice);
        }
      });
    });

    return results;
  }

  async getDashboard() {
    return await getRequest(`${route.getDashboard}`);
  }

  async getAllRegisterOnlineClassMember(offset) {
    return await getRequest(`${route.getAllRegisterOnlineClassMember}?offset=${offset}`);
  }

  async getOneRegisterOnlineClassMember(user_id) {
    return await getRequest(`${route.getOneRegisterOnlineClassMember}?user_id=${user_id}`);
  }

  async updateRegisterOnlineClassMember(data) {
    return await postRequest(`${route.updateRegisterOnlineClassMember}`, data);
  }

  // user
  async blockUser(data) {
    return await postRequest(`${route.blockUser}`, data);
  }

  async emailLogin(email, password) {
    return await postRequest(`${route.login}`, { email, password });
  }

  async getAllUsers(offset, order_by, account_type) {
    return await getRequest(`${route.getAllUsers}?offset=${offset}&order_by=${order_by}&account_type=${account_type}`);
  }

  async getOneUser(user_id) {
    return await getRequest(`${route.getOneUser}?user_id=${user_id}`);
  }

  // post
  async getAllPosts(offset, order_by, post_type_id) {
    return await getRequest(`${route.getAllPosts}?offset=${offset}&order_by=${order_by}&post_type_id=${post_type_id}`);
  }

  async getUserPosts(user_id, offset) {
    return await getRequest(
      `${route.getUserPosts}?offset=${offset}&user_id=${user_id}`
    );
  }

  async updateUser(form) {
    return await fileUpload(
      `${route.updateUser}`, form
    );
  }

  async getOnePost(post_id) {
    return await getRequest(`${route.getOnePost}?post_id=${post_id}`);
  }

  async approvePost(data) {
    return await postRequest(`${route.approvePost}`, data);
  }

  // category
  async getAllCategory(offset, level, sub_of) {
    return await getRequest(`${route.getAllCategory}?offset=${offset}${level? "&level=" + level: ""}${sub_of? "&sub_of=" + sub_of: ""}`);
  }

  async getArtistCategory() {
    return await getRequest(`${route.getArtistCategory}`);
  }

  async getOneCategory(category_id) {
    return await getRequest(
      `${route.getOneCategory}?category_id=${category_id}`
    );
  }

  async createCategory(form) {
    return await fileUpload(`${route.createCategory}`, form);
  }

  async updateCategory(form) {
    return await fileUpload(`${route.updateCategory}`, form);
  }

  async updateCategoryOrder(data) {
    return await postRequest(`${route.updateCategoryOrder}`, {data: data});
  }

  async getCategory(offset, level, sub_of) {
    return await getRequest(`${route.getAllCategory}?offset=${offset}${level? "&level=" + level: ""}${sub_of? "&sub_of=" + sub_of: ""}`);
  }

  // pattern

  async updatePattern(form) {
    return await fileUpload(`${route.updatePattern}`, form);
  }

  async getOnePattern(id) {
    return await getRequest(`${route.getOnePattern}?id=${id}`);
  }

  async getAllPattern() {
    return await getRequest(`${route.getAllPattern}`);
  }

  // setting
  async getSetting(type) {
    return await getRequest(`${route.getSetting}?type=${type}`);
  }

  async updateSetting(data) {
    return await postRequest(`${route.updateSetting}`, data);
  }

  async updateSettingImage(form) {
    return await fileUpload(`${route.updateSettingImage}`, form);
  }

  async uploadImage(form) {
    return await fileUpload(`${route.uploadImage}`, form);
  }

  //change log
  async getChangeLog(type) {
    return await getRequest(`${route.getChangeLog}?type=${type}`);
  }

  async creageChangeLog(data) {
    return await postRequest(`${route.createChangeLog}`, data);
  }

  //Post Action Type
  async getPostActionType() {
    return await getRequest(`${route.getPostActionType}`);
  }

  async updatePostActionType(data) {
    return await postRequest(`${route.updatePostActionType}`, data);
  }

  // report type
  async getAllReportType() {
    return await getRequest(`${route.getAllReportType}`);
  }

  async getOneReportType(id) {
    return await getRequest(
      `${route.getOneReportType}?id=${id}`
    );
  }

  async createReportType(data) {
    return await postRequest(`${route.createReportType}`, data);
  }

  async updateReportType(data) {
    return await postRequest(`${route.updateReportType}`, data);
  }

  // ads
  async getAllAds(offset) {
    return await getRequest(`${route.getAllAds}?offset=${offset}`);
  }

  async getOneAds(id) {
    return await getRequest(
      `${route.getOneAds}?id=${id}`
    );
  }

  async createAds(form) {
    return await fileUpload(`${route.createAds}`, form);
  }

  async updateAds(data) {
    return await fileUpload(`${route.updateAds}`, data);
  }

  async deleteAds(data) {
    return await postRequest(`${route.deleteAds}`, data);
  }

  // announcementRoute
  async getAllAnnouncement(offset) {
    return await getRequest(`${route.getAllAnnouncement}?offset=${offset}`);
  }

  async getOneAnnouncement(id) {
    return await getRequest(
      `${route.getOneAnnouncement}?id=${id}`
    );
  }

  async createAnnouncement(data) {
    return await fileUpload(`${route.createAnnouncement}`, data);
  }

  async updateAnnouncement(data) {
    return await fileUpload(`${route.updateAnnouncement}`, data);
  }

  async deleteAnnouncement(data) {
    return await postRequest(`${route.deleteAnnouncement}`, data);
  }

  // report 
  async getAllReports(offset) {
    return await getRequest(`${route.getAllReports}?offset=${offset}`);
  }

  async getOneReport(id) {
    return await getRequest(
      `${route.getOneReport}?id=${id}`
    );
  }

  async updateReportStatus(data) {
    return await postRequest(`${route.updateReportStatus}`, data);
  }

  // apk
  async getApk() {
    return await getRequest(`${route.getApk}`);
  }

  async uploadApk(form) {
    return await fileUpload(`${route.uploadApk}`, form);
  }
}

export default new API();
