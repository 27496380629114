const axios = require("axios");

export function fileUpload(url, form) {
    return axios.post(url, form, {
        method: 'POST',
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'multipart/form-data',
            token: localStorage.getItem("token"),
            language: "en"
        }
    })
        .then((response) => {
            return response.data;
        })

}

