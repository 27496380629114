import React, { Component } from 'react';
import { Form, FormGroup, Col, Input, Button} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';

class CKEditornpage extends Component {

    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.afterPaste = this.afterPaste.bind(this);
        this.state = {
            tip1: "",
            tip2: "",
        }
    }


    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("online_class_tips");
        if (result.result) {
            let data = JSON.parse(result.data.description_en);
            this.setState({ 
                tip1: data.tip1,
                tip2: data.tip2
              })
        } else {
            this.toastFail(result.message);
        }
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    onSave = async () => {
        let data = {
            description_en: JSON.stringify({
                tip1: this.state.tip1,
                tip2: this.state.tip2
            }),
            type: "online_class_tips"
        }
        let result = await API.updateSetting(data);
        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }


    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }


    render() {
        console.log(this.state.tip1)
        return (
            <div>
                <Breadcrumb title="Online Class TIps" parent="Setting" />
                <ToastContainer />
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Online Class TIps</h5>
                                </div>

                                <div className="card-body">
                                    <Form className="theme-form">
                                        <FormGroup className="row">
                                            <Col sm="12">
                                                <Input
                                                    onChange={(e) => {
                                                        this.setState({tip1: e.target.value})
                                                    }}
                                                    value={this.state.tip1}
                                                    style={{ color: "black" }}
                                                    className="form-control" type="text" placeholder="youtube tips 1" />
                                            </Col>
                                        </FormGroup>

                                        <iframe width="420" height="315"
                                        src={this.state.tip1}>
                                        </iframe>
                                    </Form>
                                </div>

                                <div className="card-body">
                                    <Form className="theme-form">
                                        <FormGroup className="row">
                                            <Col sm="12">
                                                <Input
                                                    onChange={(e) => {
                                                        this.setState({tip2: e.target.value})
                                                    }}
                                                    value={this.state.tip2}
                                                    style={{ color: "black" }}
                                                    className="form-control" type="text" placeholder="youtube tip 2" />
                                            </Col>
                                        </FormGroup>

                                        <iframe width="420" height="315"
                                        src={this.state.tip2}>
                                        </iframe>
                                    </Form>

                                    <div className="card-body">
                                    <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button>

                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}


export default CKEditornpage;
