import React, { Component } from "react";
import { Button } from "reactstrap";
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from "../common/breadcrumb.component";

class CKEditornpage extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeShowEditor = this.onChangeShowEditor.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.afterPaste = this.afterPaste.bind(this);
    this.state = {
      content: "",
      showEditor: false,
      changeLogs: [],
      type: 1, // 1 for mobile, 2 for website, 3 for backend, 4 for server
    };
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    let result = await API.getChangeLog(this.state.type);
    if (result.result) {
      this.state.changeLogs = [];
      result.data.forEach((data) => {
        this.state.changeLogs.push(data.description);
      });
      this.setState({});
    } else {
      this.toastFail(result.message);
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent,
    });
  }

  onChangeShowEditor = async (evt) => {
    this.setState({
      showEditor: !this.state.showEditor,
    });
  };

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  onSave = async () => {
    let data = {
      description: this.state.content,
      type: this.state.type,
    };
    console.log("save");
    let result = await API.creageChangeLog(data);
    if (result.result) {
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onBlur(evt) {
    // console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    // console.log("afterPaste event called with event info: ", evt);
  }

  render() {
    return (
      <div>
        <Breadcrumb title="Mobile Change Log" parent="Setting" />
        <ToastContainer />
        {/*Container-fluid starts*/}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>
                    Change Log (Show Editor{" "}
                    <input type="checkbox" onChange={this.onChangeShowEditor} />{" "}
                    ){" "}
                  </h5>
                </div>
                <div className="card-body">
                  {this.state.showEditor && (
                    <div>
                      <CKEditors
                        activeClass="p10"
                        content={this.state.content}
                        events={{
                          blur: this.onBlur,
                          afterPaste: this.afterPaste,
                          change: this.onChange,
                        }}
                      />
                      <Button
                        size="sm"
                        color="info"
                        style={{ marginTop: 10 }}
                        onClick={this.onSave}
                        className="btn-square active"
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  <div>
                    {this.state.changeLogs.map((d, index) => {
                      return (
                        <div
                          style={{ marginBottom: 30 }}
                          key={index}
                          dangerouslySetInnerHTML={{ __html: d }}
                        >
                          {/* {document.write(d)} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Container-fluid Ends*/}
      </div>
    );
  }
}

export default CKEditornpage;
