import React, { Component } from 'react';
import API from "../../request/api";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Samplepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            email: "",
            password: ""
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }

    onSubmit = async () => {
        let result = await API.emailLogin(this.state.email, this.state.password);
        if (result.result) {
            localStorage.setItem('token', result.data.token);
            this.props.history.push('/');
        } else {
            toast.error(result.message);
        }
    }

    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                <ToastContainer />
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")" }}>
                                        <div className="text-center">
                                            <img src={require('../../assets/images/app_icon.png')}
                                                className="logo-login" alt="" />
                                            <hr />
                                            {/* <div className="social-media">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-facebook txt-fb" aria-hidden="true"></i></li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-google-plus txt-google-plus"
                                                        aria-hidden="true"></i></li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-twitter txt-twitter" aria-hidden="true"></i>
                                                    </li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-linkedin txt-linkedin" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h4>LOGIN</h4>
                                            <h6>Enter your Username and Password For Login or Signup</h6>
                                            <div className="card mt-4 p-4 mb-0">
                                                <Form className="theme-form"  >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input required onChange={event => this.state.email = event.target.value} type="text" className="form-control form-control-lg" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input required onChange={event => this.state.password = event.target.value} type="password" className="form-control form-control-lg" />
                                                    </div>
                                                    {/* <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Remember me</label>
                                                    </div> */}
                                                    {/* <Link to={"/"}> */}
                                                    <div className="form-group form-row mt-3">
                                                        <div className="col-md-3">
                                                            <button onClick={this.onSubmit} type="button" className="btn btn-secondary">LOGIN
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* </Link> */}
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}


export default Samplepage;
