
export const MENUITEMS = [
    {
        path: '/', title: 'Dashboard', icon: 'icon-dashboard', sidebartitle: 'General', active: false, type: 'link'
    },
    {
        path: '/user/index', title: 'USER', icon: 'icon-user', active: false, type: 'link'
    },
    {
        path: '/artist/index', title: 'Artist', icon: 'icon-user', active: false, type: 'link'
    },
    {
        path: '/online_class_register/index', title: 'Online Class Register', icon: 'icon-user', active: false, type: 'link'
    },
    {
        path: '/feed/index', title: 'FEED', icon: 'fa fa-folder-open', active: false, type: 'link'
    },
    {
        title: 'Category', icon: 'fa fa-dropbox', type: 'sub', badgeType: 'primary', badgeValue: 'new', sidebartitle: 'Gadgets', active: false, children: [
            { path: '/category/country/index', title: 'Country', type: 'link' },
            { path: '/category/city/index', title: 'City', type: 'link' },
            { path: '/category/topic/index', title: 'Topic', type: 'link' },
            { path: '/category/category/index', title: 'Category', type: 'link' },
            { path: '/category/sub/index', title: 'Subcategory', type: 'link' },
            { path: '/category/title/index', title: 'Title', type: 'link' },
            { path: '/category/pattern/index', title: 'Pattern', type: 'link' },
        ]
    },

    {
        path: '/page/index', title: 'Page', icon: 'fa fa-exclamation', sidebartitle: 'Page', active: false, type: 'link'
    },

    {
        path: '/report/index', title: 'Report ', icon: 'fa fa-exclamation', sidebartitle: 'Report', active: false, type: 'link'
    },

    {
        path: '/report_type/index', title: 'Report Type ', icon: 'fa fa-exclamation',  active: false, type: 'link'
    },

    {
        path: '/ads/index', title: 'Ads ', icon: 'fa fa-exclamation', sidebartitle: 'Ads & Announcement', active: false, type: 'link'
    },

    {
        path: '/announcement/index', title: 'Announcement ', icon: 'fa fa-exclamation',  active: false, type: 'link'
    },

    {
         title: 'Config', icon: 'fa fa-exclamation', sidebartitle: 'SETTING', active: false, type: 'sub', children: [
            {
                path: '/other', title: 'other',  active: false, type: 'link'
            },
            {
                path: '/point_setting', title: 'Point Setting ',  active: false, type: 'link'
            },

            {
                path: '/footer_tone', title: 'Footer Tone ', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
            {
                path: '/app_share', title: 'App Share ', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
        ]
    },

    {
        title: 'Info', icon: 'fa fa-info-circle',  active: false, type: 'sub', children: [
            {
                path: '/policy', title: 'POLICY', icon: 'fa fa-exclamation', active: false, type: 'link'
            },
            
            {
                path: '/about_us', title: 'ABOUT US', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
            {
                path: '/class_tips', title: 'Online Class Tips', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
            // {
            //     path: '/shop_tips', title: 'Online Shop Tips', icon: 'fa fa-info-circle', active: false, type: 'link'
            // },
            {
                path: '/point_usage', title: 'Point Usage', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
            {
                path: '/help_content', title: 'Help Content ', icon: 'fa fa-info-circle', active: false, type: 'link'
            },
        ]
    },

   
    // {
    //     path: '/ads_banner', title: 'Ads Banner ', icon: 'fa fa-info-circle', active: false, type: 'link'
    // },
   
    // {
    //     path: '/ads_pop_up', title: 'Ads Pop Up ', icon: 'fa fa-info-circle', active: false, type: 'link'
    // },
   

    {
        path: '/apk', title: 'Test App ', icon: 'fa fa-info-circle', active: false, type: 'link'
    },

    {
        title: 'Change Log', icon: 'fa fa-dropbox', type: 'sub', badgeType: 'primary', badgeValue: 'new', sidebartitle: 'Log ', active: false, children: [
            {
                path: '/change_log_mobile', title: 'Mobile ', type: 'link'
            },
            {
                path: '/change_log_website', title: 'Website ',  type: 'link'
            },
            {
                path: '/change_log_backend', title: 'Backend ',  type: 'link'
            },
            // {
            //     path: '/change_log_server', title: 'Server ',   type: 'link'
            // },
        ]
    },

   
]
