export function getRequest(url, body) {
    return fetch(url, {
        method: 'Get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: localStorage.getItem("token"),
            language: "en"
        },
        body: JSON.stringify(body),
    })
        .then((response) => response.json());

};

