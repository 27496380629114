import React from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIdToBlock: 0,
      showAlert: false,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      order_by: null,
      data: [],
      account_type: "user",
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getAllUsers(
      (this.state.currentPage - 1) * this.state.limit,
      this.state.order_by,
      this.state.account_type
    );
    if (result.result) {
      this.setState({
        data: result.data.users,
        allItems: result.data.all,
        limit: result.data.limit,
      });
    } else {
      this.toastFail(result.message);
    }
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={this.state.userIdToBlock.is_blocked? "Unblock" : "Block"}
        confirmBtnBsStyle={this.state.userIdToBlock.is_blocked? "info" : "danger"}
        type={this.state.userIdToBlock.is_blocked? "info" : "danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirmBlock}
      >
        {this.state.userIdToBlock.is_blocked? "This will let user login again" : "This user can't login anymore"}
      </SweetAlert>
    );
  }

  onConfirmBlock = async () => {
    this.hideAlert();
    let data = {
      is_blocked: !this.state.userIdToBlock.is_blocked,
      user_id: this.state.userIdToBlock.id,
    };
    let result = await API.blockUser(data);
    if (result.result) {
      this.setState({ data: [] });
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  onOrderChange(event) {
    this.state.order_by = event.target.value;
    this.requestData();
  }

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];
    this.state.data.forEach((d, index) => {
      d.number = this.state.currentPage * index + 1;

      data.push(d);
    });
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "nick_name",
        text: "Name",
      },
      {
        dataField: "gender",
        text: "Gander",
      },
      {
        dataField: "nb_following",
        text: "Followings",
      },
      {
        dataField: "nb_follower",
        text: "Followers",
      },
      {
        dataField: "nb_like",
        text: "Like",
      },
      {
        dataField: "nb_unlike",
        text: "Unlike",
      },
      {
        dataField: "nb_comment",
        text: "Comment",
      },
      {
        dataField: "nb_share",
        text: "Share",
      },
      {
        dataField: "nb_vote_up",
        text: "Vote Up",
      },
      {
        dataField: "nb_vote_down",
        text: "Vote Down",
      },
      {
        dataField: "nb_post",
        text: "Post",
      },
      {
        dataField: "nb_invite",
        text: "Invite",
      },
      {
        dataField: "point",
        text: "Points",
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          console.log(row)

          return (
            <div style={this.state.action_margin_right}>
              <Link to={`/user/view?user_id=${row.id}`}>
                <Button
                  size="sm"
                  color="info"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  View
                </Button>
              </Link>
              {/* <Link to={"/user/edit"}>
                                <Button size="sm" color="warning" style={this.state.btn_style} className="btn-square active">Edit</Button>
                            </Link> */}
              <Button
                size="sm"
                color={row.is_blocked ? "primary" : "danger"}
                onClick={() =>
                  this.setState({ showAlert: true, userIdToBlock: row })
                }
                style={this.state.btn_style}
                className="btn-square active"
              >
                {row.is_blocked ? "Unblock" : "Block"}
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="User Index" parent="User" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          style={{ width: 300 }}
                          {...props.searchProps}
                        />
                        <select
                          className="custom-select form-control"
                          style={{
                            // marginBottom: 10,
                            marginRight: 12,
                            marginLeft: 12,
                            width: 200,
                            position: "absolute",
                          }}
                          onChange={(e) => this.onOrderChange(e)}
                        >
                          <option value="null">Last Register</option>
                          <option value="following">Following</option>
                          <option value="follower">Follower</option>
                          <option value="like">Like</option>
                          <option value="unlike">Unlike</option>
                          <option value="comment">Comment</option>
                          <option value="share">Share</option>
                          <option value="vote_up">Vote Up</option>
                          <option value="vote_down">Vote Down</option>
                          <option value="invite">Invite</option>
                          <option value="point">Points</option>
                        </select>

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Layout;
