import React from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import CountUp from 'react-countup';
import Breadcrumb from '../common/breadcrumb.component';
import API from "../../request/api";


// Import custom components

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getDashboard();
        if (!result.result) return;

        let NA = "NA";

        result = result.data;
        // hashtag
        let data = [{
            name: "Top Hashtag 1",
            text: result.popular_tag[0] || NA,
            icon: "fa-folder-open",
            color: "#ab8ce4",
            classColor: "font-primary",
            data: [25, 50, 30, 40, 60, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Hashtag 2",
            text: result.popular_tag[1] || NA,
            icon: "fa-user",
            color: "#38d3e7",
            classColor: "font-secondary",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Hashtag 3",
            text: result.popular_tag[2] || NA,
            icon: "fa-users",
            color: "#00c292",
            classColor: "font-success",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Hashtag 4",
            text: result.popular_tag[3] || NA,
            icon: "fa-dropbox",
            color: "#59a6fe",
            classColor: "font-info",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        // category
        {
            name: "Top Topic 1",
            text: result.popular_category[0] || NA,
            icon: "fa-file-image-o",
            color: "#59a6fe",
            classColor: "font-info",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Topic 2",
            text: result.popular_category[1] || NA,
            icon: "fa-file-movie-o",
            color: "#00c292",
            classColor: "font-success",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Topic 3",
            text: result.popular_category[2] || NA,
            icon: "fa-file-audio-o",
            color: "#38d3e7",
            classColor: "font-secondary",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top Topic 4",
            text: result.popular_category[3] || NA,
            icon: "fa-link",
            color: "#ab8ce4",
            classColor: "font-primary",
            data: [25, 50, 30, 40, 60, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        // subcategory
        {
            name: "Top SubCategory 1",
            text: result.popular_subcategory[0] || NA,
            icon: "fa-file-image-o",
            color: "#59a6fe",
            classColor: "font-info",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top SubCategory 2",
            text: result.popular_subcategory[1] || NA,
            icon: "fa-file-movie-o",
            color: "#00c292",
            classColor: "font-success",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top SubCategory 3",
            text: result.popular_subcategory[2] || NA,
            icon: "fa-file-audio-o",
            color: "#38d3e7",
            classColor: "font-secondary",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Top SubCategory 4",
            text: result.popular_subcategory[3] || NA,
            icon: "fa-link",
            color: "#ab8ce4",
            classColor: "font-primary",
            data: [25, 50, 30, 40, 60, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },

        //other
        {
            name: "All Contents",
            number: result.all_contents,
            icon: "fa-folder-open",
            color: "#ab8ce4",
            classColor: "font-primary",
            data: [25, 50, 30, 40, 60, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Today Users",
            text: result.today_users,
            icon: "fa-user",
            color: "#38d3e7",
            classColor: "font-secondary",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "All Users",
            number: result.all_contents,
            icon: "fa-users",
            color: "#00c292",
            classColor: "font-success",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "All Categories",
            number: result.all_category,
            icon: "fa-dropbox",
            color: "#59a6fe",
            classColor: "font-info",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Photos",
            number: result.all_photos, 
            icon: "fa-file-image-o",
            color: "#59a6fe",
            classColor: "font-info",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Videos",
            number: result.all_videos,
            icon: "fa-file-movie-o",
            color: "#00c292",
            classColor: "font-success",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Slide Show",
            number: result.all_voices,
            icon: "fa-file-audio-o",
            color: "#38d3e7",
            classColor: "font-secondary",
            data: [5, 10, 20, 14, 17, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        {
            name: "Links",
            number: result.all_links,
            icon: "fa-link",
            color: "#ab8ce4",
            classColor: "font-primary",
            data: [25, 50, 30, 40, 60, 21, 20, 10, 4, 13, 0, 10, 30, 40, 10, 15, 20]
        },
        ];

        this.setState({ data: data })
    }

    render() {
        return (
            <div className="row">
                <Breadcrumb title="Dashboard" parent="Dashboard" />
                <select className="custom-select form-control" style={{marginBottom: 10, marginRight: 12, marginLeft: 12}}>
                    <option>Last 7 days</option>
                    <option>Last 15 days</option>
                    <option>Last 30 days</option>
                    <option>All Time</option>
                </select>

                


                {this.state.data.map((d) => {
                    return <div className="col-xl-3 col-lg-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="stat-widget-dashboard">
                                    <div className="media">
                                        <i className={"fa " + d.icon}></i>
                                        <div className="media-body text-right">
                                            <h4 className="mt-0">{d.number ?
                                                <CountUp className={d.classColor} end={d.number} /> :
                                                <font size="4" className={d.classColor}>{d.text}</font>
                                            }</h4>
                                            <span>{d.name}</span>
                                        </div>
                                    </div>
                                    <div className="dashboard-chart-container-small">
                                        <Sparklines data={d.data} >
                                            <SparklinesLine color={d.color} />
                                        </Sparklines>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                })}
            </div>
        );
    }
}

export default Layout;