import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Col } from "reactstrap";
import API from "../../../../request/api";
import "react-day-picker/lib/style.css";
// import Custom Componenets
import Breadcrumb from "../../../common/breadcrumb.component";

import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Forms extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.state = {
      item: {},
      showAlert: false,
      showDeleteAlert: false,
      btn_active_image: null,
      btn_inactive_image: null,
    };
  }
  handleDayChange(day) {
    // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
    this.state.user.created_at = day;
    this.setState({});
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail() {
    toast.error("Action Fail !");
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
      showDeleteAlert: false,
    });
  };

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText="Yes, I do!"
        confirmBtnBsStyle={"warning"}
        type={"warning"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={() => {
          // this.state.item.is_deleted = 0;
          this.onConfirm();
        }}
      >
        This will update category
      </SweetAlert>
    );
  }

  deleteAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={this.state.item.is_deleted ? "Put Back" : "Delete"}
        confirmBtnBsStyle={this.state.item.is_deleted ? "info" : "danger"}
        type={this.state.item.is_deleted ? "info" : "danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirm}
      >
        {this.state.item.is_deleted
          ? "This will put back the Category"
          : "This will remove the Category"}
      </SweetAlert>
    );
  }

  onConfirm = async () => {
    this.hideAlert();

    let form = new FormData();
    form.append("category_id", this.getParam("category_id"));
    if (this.state.item.name_kh)
      form.append("name_kh", this.state.item.name_kh);

    if (this.state.item.name_en)
      form.append("name_en", this.state.item.name_en);

    if (this.state.item.is_deleted)
      form.append("is_deleted", this.state.item.is_deleted);

    if (this.state.item.active_image)
      form.append("active_image_url", this.state.item.active_image);

    if (this.state.item.inactive_image)
      form.append("inactive_image_url", this.state.item.inactive_image);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      this.props.history.goBack();
    } else {
      this.toastFail(result.message);
    }
  };

  getParam(name) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(name);
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    let result = await API.getOneCategory(this.getParam("category_id"));
    if (result.result) {
      this.setState({ item: result.data });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  onImageChange(event, type) {
    // Assuming only image
    if (type == 1) {
      this.state.item.active_image_url = null;
      this.state.item.active_image = event.target.files[0];
    } else {
      this.state.item.inactive_image_url = null;
      this.state.item.inactive_image = event.target.files[0];
    }

    this.setState({});
  }

  render() {
    let activeImageSrc;
    if (this.state.item.active_image_url) {
      activeImageSrc = this.state.item.active_image_url.includes("upload/")
        ? API.serverUrl() + this.state.item.active_image_url
        : this.state.item.active_image_url;
    } else if (this.state.item.active_image) {
      activeImageSrc = URL.createObjectURL(this.state.item.active_image);
    } else {
      activeImageSrc = "";
    }

    let inactiveImageSrc;
    if (this.state.item.inactive_image_url) {
      inactiveImageSrc = this.state.item.inactive_image_url.includes("upload/")
        ? API.serverUrl() + this.state.item.inactive_image_url
        : this.state.item.inactive_image_url;
    } else if (this.state.item.inactive_image) {
      inactiveImageSrc = URL.createObjectURL(this.state.item.inactive_image);
    } else {
      inactiveImageSrc = "";
    }
    return (
      <div>
        <Breadcrumb title="Update" parent="Category" />
        {this.state.showAlert && this.sweetAlert()}
        {this.state.showDeleteAlert && this.deleteAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xl-6">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Update</h5>
                      <span>
                        {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                      </span>
                    </div>
                    <div className="card-body">
                      <Form className="theme-form">
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            English Name
                          </Label>
                          <Col sm="9">
                            <Input
                              onChange={(e) => {
                                this.state.item.name_en = e.target.value;
                                this.setState({});
                              }}
                              value={this.state.item.name_en}
                              className="form-control"
                              type="text"
                              placeholder="English Name"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Khmer Name
                          </Label>
                          <Col sm="9">
                            <Input
                              onChange={(e) => {
                                this.state.item.name_kh = e.target.value;
                                this.setState({});
                              }}
                              value={this.state.item.name_kh}
                              className="form-control"
                              type="text"
                              placeholder="Khmer Name"
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Active Image
                          </Label>
                          <Col sm="3">
                            <Button
                              color="info"
                              type="button"
                              onClick={() =>
                                this.state.btn_active_image.click()
                              }
                              className="btn-pill btn-info btn-air-info"
                            >
                              Choose Image
                            </Button>
                            <input
                              ref={(ref) => (this.state.btn_active_image = ref)}
                              style={{ display: "none" }}
                              type="file"
                              onChange={(e) => this.onImageChange(e, 1)}
                            />
                          </Col>
                          <Col sm="6">
                            <img
                              src={activeImageSrc}
                              className="img-100 m-l-35"
                              // style={{width: 300, height: 300}}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="card-footer">
                      <Button
                        onClick={() => this.setState({ showAlert: true })}
                        color="primary"
                      >
                        Submit
                      </Button>{" "}
                      <Button
                        onClick={() => this.props.history.goBack()}
                        color="secondary"
                      >
                        Cancel
                      </Button>{" "}
                      {/* <Button style={{marginLeft: 100}} onClick={() => this.setState({ showDeleteAlert: true })} color={this.state.item.is_deleted? "info" : "danger"}>{this.state.item.is_deleted? "Put Back" : "Delete"}</Button>{' '} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forms;
