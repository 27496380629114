import React from "react";
import Breadcrumb from "../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      data: [],
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
      item: {
        apk: null,
        name: "",
        description: "",
      },
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getApk();
    if (result.result) {
      this.setState({
        data: result.data,
        allItems: 10,
        limit: result.data.limit,
      });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText="Block"
        confirmBtnBsStyle={"danger"}
        type={"danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onComfirtBlock}
      >
        This user can't login anymore
      </SweetAlert>
    );
  }

  onComfirtBlock = () => {
    this.hideAlert();
    this.toastSuccess();
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  onImageChange(event, type) {
    // Assuming only image
    this.state.item.apk = event.target.files[0];

    this.setState({});
  }

  onSubmit = async () => {

    if (!this.state.item.apk || ! this.state.item.name) {
        return alert("Mission Info")
    }
    let form = new FormData();

    form.append("apk", this.state.item.apk);
    form.append("name", this.state.item.name);
    form.append("description", this.state.item.description);

    let result = await API.uploadApk(form);

    if (result.result) {
        this.toastSuccess();
        this.requestData();
    } else {
        this.toastFail(result.message)
    }
}

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];
    this.state.data.forEach((d, index) => {
      d.number = this.state.currentPage * index + 1;
      d.description = d.link;
      data.push(d);
    });
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "name",
        text: "Version Name",
      },
      {
        dataField: "description",
        text: "Description",
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Button
                size="sm"
                color="info"
                style={this.state.btn_style}
                className="btn-square active"
                onClick={() => {
                  if (!navigator.clipboard) {
                    alert("error");
                    return;
                  }
                  navigator.clipboard.writeText(row.link).then(
                    function() {
                      alert("Link Copied!");
                    },
                    function(err) {
                      alert("error to copy");
                      console.error("Async: Could not copy text: ", err);
                    }
                  );
                }}
              >
                Copy Link
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="City Index" parent="Category" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          {...props.searchProps}
                          onSearch={(v) => (this.state.item.name = v)}
                        />
                        <Button
                          style={{ marginLeft: 10 }}
                          color="info"
                          className="btn-pill btn-info btn-air-info"
                          onClick={() => this.state.btn_apk.click()} 
                        >
                          Choose App
                        </Button>
                        <input
                          ref={(ref) => (this.state.btn_apk = ref)}
                          style={{ display: "none" }}
                          type="file"
                          onChange={(e) => this.onImageChange(e, 2)}
                        />
                        <Button
                          style={{ marginLeft: 10 }}
                          color="primary"
                          onClick={() => this.onSubmit()}
                          // className="btn-pill btn-info btn-air-info"
                        >
                          Submit
                        </Button>

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Layout;
