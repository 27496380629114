import React, { Component } from 'react';
import { Button } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../request/api";
// import Custom Componenets
import Breadcrumb from '../common/breadcrumb.component';

class CKEditornpage extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.afterPaste = this.afterPaste.bind(this);
        this.state = {
            content: '',
        }
    }


    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("point");
        if (result.result) {
            this.setState({ content: result.data.description_en })
        } else {
            this.toastFail(result.message);
        }
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })

    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    onSave = async () => {
        let data = {
            description_en: this.state.content,
            type: "point"
        }
        let result = await API.updateSetting(data);
        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }


    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }


    render() {
        return (
            <div>
                <Breadcrumb title="Point Usage" parent="Setting" />
                <ToastContainer />
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Point Usage</h5>
                                </div>
                                <div className="card-body">
                                    <CKEditors
                                        activeClass="p10"
                                        content={this.state.content}
                                        events={{
                                            "blur": this.onBlur,
                                            "afterPaste": this.afterPaste,
                                            "change": this.onChange
                                        }}
                                    />
                                    <Button size="sm" color="info" style={{ marginTop: 10 }} onClick={this.onSave} className="btn-square active">Save</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}
            </div>
        )
    }
}


export default CKEditornpage;
