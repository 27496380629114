import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvSelect, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';

import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            user: {
                first_name: "Wandy",
                last_name: "Rous",
                nick_name: "RWD",
                email: "wandy@gmail.com",
                phone_number: "012 345 678",
                bio: "I am love coding",
                created_at: "2020-02-26",
                status: 2,
                profile_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS-UjETj1xbgFNTOXBSvxU0APPQvBA9MP4c9gOaMIQ5X4FzL1Tb"
            },
            showAlert: false,
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail() {
        toast.error("Action Fail !")
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Do you really want to edit?"
            onCancel={this.hideAlert}
            onConfirm={this.onComfirm}
        >
            This will change user information
    </SweetAlert>
    }

    onComfirm = () => {
        this.hideAlert();
        this.toastSuccess();
        // this.props.history.goBack();
    }

    render() {
        let status;
        if (this.state.user.status == 1) {
            status = <span className={"badge badge-success"}>Verified</span>
        } else if (this.state.user.status == 0) {
            status = <span className={"badge badge-danger"}>Blocked</span>
        } else {
            status = <span className={"badge badge-warning"}>Not Verified</span>
        }
        return (
            <div>
                <Breadcrumb title="User Edit" parent="User" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Edit User</h5><span>
                                                <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                />
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        First Name</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.user.first_name = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.first_name}
                                                            className="form-control" type="text" placeholder="First Name" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Last Name</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.user.last_name = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.last_name}
                                                            className="form-control" type="text" placeholder="Last Name" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Nick Name</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.user.nick_name = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.nick_name}
                                                            className="form-control" type="text" placeholder="Nick Name" />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
                                                    <Col sm='9'>
                                                        <input
                                                            onChange={(e) => {
                                                                this.state.user.email = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.email}
                                                            className="form-control" id="inputEmail3" type="email" placeholder="Email" />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Phone</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.user.phone_number = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.phone_number}
                                                            className="form-control" type="text" placeholder="Phone Number" />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Bio</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.user.bio = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.user.bio}
                                                            className="form-control" type="text" placeholder="Bio" />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Join Date</Label>
                                                    <Col sm="9">
                                                        <DayPickerInput
                                                            value={this.state.user.created_at}
                                                            onDayChange={this.handleDayChange} />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Status</Label>
                                                    <Col sm="9">
                                                        <h5>{status}</h5>
                                                    </Col>
                                                </FormGroup>

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.setState({showAlert: true})} color='primary'>Submit</Button>{' '}
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
