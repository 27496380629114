import React from 'react';
import Breadcrumb from '../../common/breadcrumb.component';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button } from 'reactstrap';
import API from "../../../request/api";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//json file

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            limit: 15,
            allItems: 0,
            currentPage: 1,
            data: [],
            btn_style: {
                marginRight: 10,
            },
            action_margin_right: {
                marginRight: 0
            }
        };
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        this.setState({
            data: []
        });
        let result = await API.getAllReportType();
        if (result.result) {
            result.data.forEach(d => {
                this.state.data.push(d)
            })
            this.setState({})
        } else {
            this.toastFail(result.message);
        }
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Block"
            confirmBtnBsStyle={"danger"}
            type={"danger"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirmBlock}
        >
            This user can't login anymore
    </SweetAlert>
    }

    onConfirmBlock = () => {
        this.hideAlert();
        this.toastSuccess()
    }

    onPageChanged(page) {
        this.requestData();
        this.setState({ currentPage: page })
    }

    render() {
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'id',
                text: 'N',
            },
            {
                dataField: 'name_en',
                text: 'Name En',
            },
            {
                dataField: 'name_kh',
                text: 'Name Kh',
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (rowContent, row) => {
                    return (
                        <div  style={this.state.action_margin_right}>
                            <Link to={`/report_type/view?id=${row.id}`}>
                                <Button size="sm" color="info" style={this.state.btn_style} className="btn-square active">View</Button>
                            </Link>
                            <Link to={`/report_type/edit?id=${row.id}`}>
                                <Button size="sm" color="warning" style={this.state.btn_style} className="btn-square active">Edit</Button>
                            </Link>
                            {/* <Button size="sm" color="danger" onClick={() => this.setState({ showAlert: true })} style={this.state.btn_style} className="btn-square active">Block</Button> */}

                        </div>
                    )
                }
            }];

        return <div >
            <Breadcrumb title="Report Index" parent="Report" />
            {this.state.showAlert && this.sweetAlert()}
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mb-0">

                            <div className="card-body datatable-react">
                                <ToolkitProvider
                                    keyField="name"
                                    data={this.state.data}
                                    columns={columns}
                                // search
                                >
                                    {
                                        props => (
                                            <div >
                                                <SearchBar {...props.searchProps} />
                                                <Link to={"/report_type/new"} style={{position: "absolute", right: 30,}}>
                                                <Button  color="info" className="btn-pill btn-info btn-air-info">New</Button>
                                                </Link>

                                                <BootstrapTable
                                                    {...props.baseProps}
                                                // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    }
}

export default Layout;