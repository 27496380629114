import React from 'react';
import Breadcrumb from '../../common/breadcrumb.component';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button } from 'reactstrap';
import API from "../../../request/api";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//json file

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            limit: 15,
            allItems: 0,
            currentPage: 1,
            data: [],
            btn_style: {
                marginRight: 10,
            },
            action_margin_right: {
                marginRight: 0
            },
            level: 10 // 1 country, 2 topic, 3 sub // 10 city
        };
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        this.setState({
            data: []
        })
        let result = await API.getAllReports(this.state.data.length);
        console.log(result)
        if (result.result) {
            this.setState({
                data: result.data.reports,
                allItems: result.data.all,
                limit: result.data.limit,
            })
        } else {
            this.toastFail(result.message);
        }
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Block"
            confirmBtnBsStyle={"danger"}
            type={"danger"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onComfirtBlock}
        >
            This user can't login anymore
    </SweetAlert>
    }

    onComfirtBlock = () => {
        this.hideAlert();
        this.toastSuccess()
    }

    onPageChanged(page) {
        this.requestData()
        this.setState({ currentPage: page })
    }

    render() {
        let totalPage = Math.ceil(this.state.allItems / this.state.limit);
        let paginations = [];
        for (let i = 1; i <= totalPage; i++) {

            paginations.push(<a key={i} href="#" onClick={() => this.onPageChanged(i)} className={"badge badge-" + (this.state.currentPage == i ? "info" : "light")}>{i}</a>)
        }
        var data = [];
        this.state.data.forEach((d, index) => {
            d.number = this.state.currentPage * index + 1;
            data.push(d);
        })
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'number',
                text: 'N',
            },
            {
                dataField: 'name',
                text: 'Report by',
            },
            {
                dataField: 'report_type',
                text: 'Report Type',
            },
            {
                // dataField: "is_approved",
                text: "Status",
                formatter: (rowContent, row) => {
                    console.log(row)
                  let status;
                  if (row.report_status == "Pending") {
                    status = <span className={"badge badge-warning"} style={{color: "white"}}>Pending</span>;
                    
                  } else  {
                    status = <span className={"badge badge-success"}>Solved</span>;
                  } 
                  return status;
                },
              },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (rowContent, row) => {
                    return (
                        <div style={this.state.action_margin_right}>
                            <Link to={`/report/view?id=${row.report_id}`}>
                                <Button size="sm" color="info" style={this.state.btn_style} className="btn-square active">View</Button>
                            </Link>
                            {/* <Button size="sm" color="danger" onClick={() => this.setState({ showAlert: true })} style={this.state.btn_style} className="btn-square active">Block</Button> */}

                        </div>
                    )
                }
            }];

        return <div >
            <Breadcrumb title="Report Index" parent="Report" />
            {this.state.showAlert && this.sweetAlert()}
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mb-0">

                            <div className="card-body datatable-react">
                                <ToolkitProvider
                                    keyField="name"
                                    data={data}
                                    columns={columns}
                                // search
                                >
                                    {
                                        props => (
                                            <div >
                                                <SearchBar {...props.searchProps} />
                                                

                                                <BootstrapTable
                                                    {...props.baseProps}
                                                // pagination={paginationFactory(options)}
                                                />
                                                <div className="card-body digits"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row" }}
                                                >
                                                    <i className="icon-angle-left"
                                                        onClick={() => {
                                                            if (this.state.currentPage !== 1) {
                                                                this.onPageChanged(this.state.currentPage - 1)
                                                            }
                                                        }}
                                                        style={{ marginRight: 5 }}></i>
                                                    {paginations}
                                                    <i
                                                        onClick={() => {
                                                            if (this.state.currentPage !== totalPage) {
                                                                this.onPageChanged(this.state.currentPage + 1)
                                                            }
                                                        }}
                                                        className="icon-angle-right"
                                                    ></i>
                                                </div>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    }
}

export default Layout;