import React from "react";
import Breadcrumb from "../../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../../request/api";

import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../node_modules/react-resizable/css/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    let result = await API.getAllPattern();
    this.setState({data: result.data})
  }

  render() {
    var data = [];
    const { SearchBar } = Search;
    this.state.data.forEach((d, index) => {
      let type = ["", "Color", "Gradiant", "Pattern"];
      d.type = type[d.type_id];
      d.number = ++index 
      data.push(d)
      }

      
    );
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "type",
        text: "Type",
      },
      {
        text: "Preview",
        formatter: (rowContent, row) => {
          return (
            <div
              style={{
                width: 70, height: 70,
                position: "relative"
              }}
            >
              {
                row.type_id == 1 &&
                <div
                style={{width: 70, height: 70, backgroundColor: row.background_color}}
              />
              }

{
                (row.type_id == 2 || row.type_id == 3 )&&
                <img
                    src={API.getImageURL(row.image_url)}
                    style={{width: 70, height: 70}}
                />
              }
              <span
                style={{
                  position: "absolute",
                  color: row.text_color, 
                  top: 25,
                  left: 20,
              }}   
              >
                Hello
              </span>
            </div>
          );
        },
      },

      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Link to={`/category/pattern/new?id=${row.id}`}>
                <Button
                  size="sm"
                  color="info"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  Edit
                </Button>
              </Link>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="Index" parent="Pattern" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />

        {/* LIST VIEW BODY */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    
                    {(props) => (
                      
                      <div>
                        <SearchBar {...props.searchProps} />
                        <Link
                          to={"/category/pattern/new"}
                          style={{ position: "absolute", right: 30 }}
                        >
                          <Button
                            color="info"
                            className="btn-pill btn-info btn-air-info"
                          >
                            New
                          </Button>
                        </Link>

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default Layout;
