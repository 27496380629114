import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebar: true,
            navMenus: false,
            showAlert: false,
        }
    }

    componentWillMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            this.setState({ sidebar: false })
        }
        this.checkAuth()
    }

    componentDidMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        }
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do"
            confirmBtnBsStyle={"danger"}
            type={"danger"}
            title="Do you want to sign out?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfrim}
        >

        </SweetAlert>
    }

    onConfrim = () => {
        this.hideAlert();
        localStorage.clear("token");
        this.props.history.push('/login');
    }

    checkAuth() {
        if (!localStorage.getItem("token")) {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <div className="page-main-header">
                {this.state.showAlert && this.sweetAlert()}
                <div className="main-header-left">
                    <div className="logo-wrapper">
                        <Link to={"/"} >
                            <img
                                style={{ width: 250, height: 50 }}
                                src={require('../../../assets/images/logo_text.png')} alt="" />
                        </Link>
                    </div>
                    <img className="img-50 rounded-circle m-l-30" src={require("./../../../assets/images/user/1.jpg")} alt="#" />

                    <h3 className="m-t-15 m-l-20 img-100">Wandy</h3>

                </div>
                <div className="main-header-right row">
                    <div className="nav-right col">
                        <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')} >
                            <button type="button" className="btn btn-danger sweet-5"
                                onClick={() => this.setState({ showAlert: true })}>
                                Sign Out
                            </button>
                        </ul>
                        <div className="d-lg-none mobile-toggle">
                            <i className="icon-more"></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header)