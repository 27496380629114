import React from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Slider from "react-slick";
import API from "../../../request/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertType: "approve",
      dumyData: [
        {
          id: 1,
          user: {
            name: "Windy",
            last_name: "Rous",
            nick_name: "Makara VD",
            profile_url: require("./../../../assets/images/user/1.jpg"),
          },
          description: "Learn from 0 to Hero",
          post_type_id: 5,
          price: 100,
          topic: "Education",
          category: "Math",
          title: "Math class for beginner",
          image_url:
            "https://images.theconversation.com/files/308619/original/file-20200106-123395-goukla.jpg?ixlib=rb-1.1.0&rect=16%2C24%2C5475%2C3639&q=45&auto=format&w=496&fit=clip",
          post_file: [
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 1",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 2",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 3",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 4",
            },
          ],
          created_at: "31-01-2019",
          share_link: "server url and post id here",
        },
        {
          id: 2,
          user: {
            name: "Windy",
            last_name: "Rous",
            nick_name: "Makara VD",
            profile_url: require("./../../../assets/images/user/1.jpg"),
          },
          description: "Ecommer Shop",
          post_type_id: 6,
          price: 100,
          topic: "Education",
          category: "Math",
          title: "Math class for beginner",
          image_url:
            "https://images.theconversation.com/files/308619/original/file-20200106-123395-goukla.jpg?ixlib=rb-1.1.0&rect=16%2C24%2C5475%2C3639&q=45&auto=format&w=496&fit=clip",
          post_file: [
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 1",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 2",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 3",
            },
            {
              video_url:
                "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
              title: "clip 4",
            },
          ],
          created_at: "31-01-2019",
          share_link: "server url and post id here",
        },
        {
          id: 1,
          first_name: "Windy",
          last_name: "Rous",
          nick_name: "Wandy Rous PH",
          description:
            "This is the template description for photos. This is one of the beautiful design of photos.",
          post_type: 1,
          profile_url: require("./../../../assets/images/user/1.jpg"),
          image_url: [
            "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/nature-quotes-1557340276.jpg?crop=0.666xw:1.00xh;0.168xw,0&resize=640:*",
            "https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg",
            "https://www.yesmagazine.org/wp-content/uploads/imports/97c16c71e4ac4236bc5058d63a667663.png",
          ],
          created_at: "31-01-2019",
          share_link: "server url and post id here",
        },
        {
          id: 2,
          first_name: "Windy",
          last_name: "Rous",
          nick_name: "Makara VD",
          description:
            "Video makes the app more appearling and beautiful. Video is the best form of communication",
          post_type: 2,
          profile_url: require("./../../../assets/images/user/1.jpg"),
          image_url:
            "https://images.theconversation.com/files/308619/original/file-20200106-123395-goukla.jpg?ixlib=rb-1.1.0&rect=16%2C24%2C5475%2C3639&q=45&auto=format&w=496&fit=clip",
          video_url:
            "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
          created_at: "31-01-2019",
          share_link: "server url and post id here",
        },
        {
          id: 3,
          first_name: "Windy",
          last_name: "Rous",
          nick_name: "John Cena SS",
          description: "Slide Show of John Cena. Really?",
          post_type: 3,
          image_url: require("./../../../assets/images/user/1.jpg"),
          profile_url:
            "https://i.pinimg.com/originals/40/32/e0/4032e0031e2e95989f1e76fe3d4f57b7.jpg",
          data: [
            {
              file:
                "https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg",
              voice:
                "https://gofreshnow.com/wp-content/uploads/2019/09/westlife-soledad.mp3",
            },
            {
              file:
                "https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg",
              voice:
                "https://gofreshnow.com/wp-content/uploads/2019/09/westlife-soledad.mp3",
            },
          ],
          video_url:
            "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
          created_at: "31-01-2019",
          share_link: "server url and post id here",
        },
        {
          id: 4,
          first_name: "Windy",
          last_name: "Rous",
          nick_name: "Big Show SL",
          description:
            "Yes. This is Slideshow. To show the content you need to click it. Slideshow is the best.",
          post_type: 4,
          profile_url: require("./../../../assets/images/user/1.jpg"),
          image_url:
            "https://dcrp.org.uk/wp-content/uploads/2018/08/video-thumbnail.gif",
          video_url:
            "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4",
          created_at: "31-01-2019",
          link: "https://www.youtube.com/",
        },
      ],
      item: null,
    };
  }

  getParam(name) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(name);
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    let result = await API.getOnePost(this.getParam("post_id"));
    if (result.result) {
      console.log(result.data);
      this.setState({ item: result.data });
    } else {
      this.toastFail(result.message);
    }
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
    // this.props.history.goBack();
  };

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  sweetAlert() {
    let type =
      this.state.alertType == "approve"
        ? {
            text: "Are you sure you want to Approve?",
            style: "success",
          }
        : {
            text: "Are you sure you want to Reject?",
            style: "danger",
          };

    return (
      <SweetAlert
        showCancel
        confirmBtnText="Yes, I am sure!!!"
        confirmBtnBsStyle={type.style}
        type={type.style}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirm}
      >
        {type.text}
      </SweetAlert>
    );
  }

  onConfirm = async () => {
    this.hideAlert();
    let data = {
      is_approved: this.state.alertType == "approve" ? 1 : -1,
      post_id: this.state.item.id,
    };
    let result = await API.approvePost(data);
    if (result.result) {
      this.setState({ data: [] });
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  component() {
    if (this.state.item.post_type_id == 1) {
      return this.onlineShop();
      // return this.photos();
    } else if (this.state.item.post_type_id == 2) {
      return this.video();
    } else if (this.state.item.post_type_id == 3) {
      return this.slideShow();
    } else if (this.state.item.post_type_id == 5) {
      return this.onlineClass();
    } else if (this.state.item.post_type_id == 6) {
      return this.onlineShop();
    } else {
      return this.sharedLink();
    }
  }

  photos() {
    return (
      <Slider {...this.lazyload_settings}>
        {this.state.item.data.map((d) => (
         <div key={d.id}>
           <img className="p-2" src={d.file} alt="" />
         </div>
        ))}
      </Slider>
    );
  }

  lazyload_settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  video() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "40vh",
          marginBottom: 20,
          maxHeight: "10%"
        }}
      >
        <video
          style={{ width: "25%", maxHeight: "25%" }}
          controls={true}
          src={this.state.item.data[0].file}
        />
      </div>
    );
  }

  slideShow() {
    return (
      <Slider {...this.lazyload_settings}>
        {this.state.item.data.map((d, index) => (
          <div className="p-2">
            <img src={d.file} style={{ width: "100%" }} alt="" />
            {
              index == 0 &&
              <ReactAudioPlayer
              // style={{width: 300}}
              src={d.voice}
              controls
            />
            }
          </div>
        ))}
      </Slider>
    );
  }

  sharedLink() {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
          paddingLeft: "15%",
        }}
      >
        <a
          href="#"
          onClick={() => this.openInNewTab(this.state.item.data[0].link)}
        >
          <img
            src={this.state.item.image_preview}
            style={{ width: "80%" }}
            alt=""
          />
        </a>

        <div style={{ backgroundColor: "#d6d6d6", width: "80%" }}>
          <p style={{ paddingTop: 10 }}>
            {this.state.item.data[0] ? this.state.item.data[0].link : ""}
          </p>
          <p>
            {this.state.item.data[0]
              ? this.state.item.data[0].link_description
              : ""}
          </p>
        </div>
      </div>
    );
  }

  onlineShop() {
    return (
      <div>
        {this.state.item.data.map((d) => {
          return (
            <img
              src={d.file}
              className="img-100  m-r-10"
              // style={{width: 300, height: 300}}
            />
          );
        })}
      </div>
    );
  }

  onlineClass() {
    return (
      <div>
        {this.state.item.data.map((d) => {
          return (
            <div
              style={{
                flex: 1,
                display: "inline-block",
                float: "left",
                marginRight: 10,
                maxHeight: "30%"
              }}
            >
              <video

                style={{ width: "40%", maxHeight: "30%" }}
                controls src={d.file} />
              <div style={{ "textAlign": "center" }}>
                <p>{d.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  detailOnlineClassComponent() {
    return (
      <div className="row">
        <div className="col-sm-12 col-xl-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Feed Detail</h5>
                </div>
                <div className="card-body">
                  <Form className="theme-form">
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Description
                      </Label>
                      <Col sm="9">
                        <Col sm="9">
                          <Label
                            className="col-sm-9 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            {this.state.item.description}
                          </Label>
                        </Col>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Price
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.price}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Category
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.category_name}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Title
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.title}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Tag
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.tag}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Stock Type
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.stock_type || ""}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Color
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.color || ""}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Size
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.size || ""}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Delivery Price
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.delivery_price}
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Website
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          {this.state.item.website}
                        </Label>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div className="card-footer">
                  {/* <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '} */}
                  <Button
                    onClick={() => this.props.history.goBack()}
                    color="secondary"
                  >
                    Go Back
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  detailOnlineShopComponent() {
    return (
      <div className="row">
        <div className="col-sm-12 col-xl-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Feed Detail</h5>
                  <span>
                    {/* <img
                                src={this.state.user.profile_url}
                                className="img-100 rounded-circle"
                            // style={{width: 300, height: 300}}
                            /> */}
                  </span>
                </div>
                <div className="card-body">
                  <Form className="theme-form">
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Description
                      </Label>
                      <Col sm="9">
                        <Col sm="9">
                          <Label
                            className="col-sm-9 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Ecommerce Shop is everywhere
                          </Label>
                        </Col>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Price
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          300
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Color
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          Red, Green, Blue
                        </Label>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Size
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          S, M, L
                        </Label>
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Phone Number
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          012 345 678
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Email
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          seller@gmail.com
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Website
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          e-commerce.com
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Address
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          St.36, Home 168, Beng tra bek, Phnom Penh
                        </Label>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputPassword3"
                      >
                        Title
                      </Label>
                      <Col sm="9">
                        <Label
                          className="col-sm-9 col-form-label m-l-15"
                          htmlFor="inputPassword3"
                        >
                          Best goods for everyone
                        </Label>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div className="card-footer">
                  {/* <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '} */}
                  <Button
                    onClick={() => this.props.history.goBack()}
                    color="secondary"
                  >
                    Go Back
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  footer() {
    return this.detailOnlineClassComponent();

    if (this.state.item.post_type_id == 1) {
      return this.photos();
    } else if (this.state.item.post_type_id == 2) {
      return this.video();
    } else if (this.state.item.post_type_id == 3) {
      return this.slideShow();
    } else if (this.state.item.post_type_id == 5) {
      return this.detailOnlineShopComponent();
    } else if (this.state.item.post_type_id == 6) {
    } else {
      return this.sharedLink();
    }
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  body() {
    if (!this.state.item) {
      return <p></p>;
    }

    return (
      <div>
        <Breadcrumb title="View" parent="Feed" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        {/*Container-fluid starts*/}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <img
                      src={this.state.item.user.profile_url}
                      className="img-50 rounded-circle"
                    />
                    <Link to={`/user/view?user_id=${this.state.item.user.id}`}>
                      <p className="m-l-10 f-20">{this.state.item.user.name}</p>
                    </Link>

                    <div style={{ position: "absolute", right: 100 }}>
                      <Row>
                      </Row>
                    </div>
                  </Row>
                </div>
                <div className="card-body">
                  {/* <p>{this.state.item.description}</p> */}

                  {this.component()}
                </div>
              </div>
            </div>
          </div>
          {this.footer()}
        </div>
        {/*Container-fluid Ends*/}
      </div>
    );
  }

  render() {
    return this.body();
  }
}

export default Layout;
