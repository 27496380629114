import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import API from "../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            nick_name: "",
            bio: "",
            profile_url: null,
            category_id: 0,
            showAlert: false,
            btn_active_image: null,
            tag: "",
            category: [],
            user_id: this.getParam("id")
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    UNSAFE_componentWillMount() {
        this.requestData();
        if (this.state.user_id) {
            this.requestOneUser(this.state.user_id)
        }
    }

    async requestOneUser() {
        let result = await API.getOneUser(this.state.user_id);

        let user = result.data.user;

        console.log(user)

        this.setState({
            nick_name: user.nick_name,
            bio: user.bio,
            profile_url: user.profile_url,
            category_id: user.category_id,
            tag: user.tag
        })

    }

    async requestData() {
        let result = await API.getArtistCategory();
        
        if (result.result) {
            if (!this.state.category_id && result.data.length > 0) {
                this.state.category_id = result.data[0].id
            }
            this.setState({
                category: result.data,
            })
        } else {
            this.toastFail(result.message);
        }
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirm}
        >
            This will create new category
    </SweetAlert>
    }

    onConfirm = async () => {
        this.hideAlert();

        let form = new FormData();

        if (this.state.user_id) {
            form.append("user_id", this.state.user_id);
        }

        form.append("nick_name", this.state.nick_name);
        form.append("tag", this.state.tag);
        form.append("bio", this.state.bio);
        form.append("category_id", this.state.category_id);

        if (!this.state.profile_url || this.state.profile_url == "") {
            return alert("Missing Image!")
        }

       try {
        if (!this.state.profile_url.includes("upload")) {
            form.append("image_url", this.state.profile_url);
        }
       } catch (error) {
        form.append("image_url", this.state.profile_url);
       }
       
        form.append("account_type", "artist");

        let result = await API.updateUser(form);

        if (result.result) {
            this.toastSuccess();
            this.props.history.goBack();
        } else {
            this.toastFail(result.message)
        }
    }

    onImageChange(event, type) {
        this.setState({profile_url: event.target.files[0]})
    }

    getParam(name) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        return url.searchParams.get(name);
    }

    render() {

        let activeImageSrc;
        try {
            if (this.state.profile_url) {
                activeImageSrc = this.state.profile_url.includes("upload/")
                ? API.getImageURL(this.state.profile_url) : this.state.profile_url;
            } else if (this.state.profile_url) {
                activeImageSrc = URL.createObjectURL(this.state.profile_url);
            } else {
                activeImageSrc = "";
            }
        } catch (error) {
            activeImageSrc = URL.createObjectURL(this.state.profile_url);
        }
        
        return (
            <div>
                <Breadcrumb title="Artist New" parent="Artist" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>New Artist</h5><span>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Name: @</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.setState({nick_name: e.target.value})
                                                            }}
                                                            value={this.state.nick_name}
                                                            className="form-control" type="text" placeholder="user name" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Bio</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.setState({bio: e.target.value})
                                                            }}
                                                            value={this.state.bio}
                                                            className="form-control" type="text" placeholder="Bio" />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Tag</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            placeholder="single, actor, pop music"
                                                            onChange={(e) => {
                                                                this.setState({tag: e.target.value})
                                                            }}
                                                            value={this.state.tag}
                                                            className="form-control" type="text" />
                                                    </Col>
                                                </FormGroup>


                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Category</Label>
                                                    <Col sm="9">
                                                        <select className="col-sm-9 form-control" onChange={(e) => this.setState({category_id: e.target.value})}>
                                                            {
                                                                this.state.category.map((category, index) => 
                                                                <option key={category.id } selected={category.id == this.state.category_id} value={category.id}>
                                                                    {category.name_en}
                                                                </option>
                                                                
                                                            )}

                                                        </select>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Profile</Label>
                                                    <Col sm="3">
                                                        <Button color="info" type="button" onClick={() => this.state.btn_active_image.click()} className="btn-pill btn-info btn-air-info">Choose Image</Button>
                                                        <input ref={(ref) => this.state.btn_active_image = ref} style={{ display: "none" }} type="file" onChange={(e) => this.onImageChange(e)} />
                                                    </Col>
                                                    <Col sm="6">
                                                        <img
                                                            src={activeImageSrc}
                                                            className="img-100 m-l-35"
                                                        // style={{width: 300, height: 300}}
                                                        />
                                                    </Col>

                                                </FormGroup>

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                            <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
