import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import API from "../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Breadcrumb from '../../common/breadcrumb.component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            user: {},
            limit: 15,
            allItems: 0,
            currentPage: 1,
            data: [],
        };

    }

    UNSAFE_componentWillMount() {
        this.requestData();
        this.requestFeed()
    }

    async requestFeed() {
        let result = await API.getUserPosts(this.getParam("user_id"), (this.state.currentPage - 1) * this.state.limit);
        if (result.result) {
            this.setState({
                data: result.data.posts,
                allItems: result.data.all,
                limit: result.data.limit,
            })
        } else {
            this.toastFail(result.message);
        }
    }

    async requestData() {
        let result = await API.getOneUser(this.getParam("user_id"));
        if (result.result) {
            this.setState({ user: result.data.user })
        } else {
            this.toastFail(result.message);
        }
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    getParam(name) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        return url.searchParams.get(name);
    }

    handleDayChange(day) {
        this.state.user.created_at = day;
        this.setState({});
    }

    onPageChanged(page) {
        this.requestFeed()
        this.setState({ currentPage: page })
    }

    userFeed() {
        let totalPage = Math.ceil(this.state.allItems / this.state.limit);
        let paginations = [];
        for (let i = 1; i <= totalPage; i++) {

            paginations.push(<a key={i} href="#" onClick={() => this.onPageChanged(i)} className={"badge badge-" + (this.state.currentPage == i ? "info" : "light")}>{i}</a>)
        }
        var data = [];
        this.state.data.forEach((d, index) => {
            d.number = this.state.currentPage * index + 1;
            data.push({
                id: d.id,
                name: d.user.name,
                description: d.description,
                type_name: d.type_name,
                is_approved: d.is_approved,
                number: d.number,
            });
        })
        const columns = [
            {
                dataField: 'number',
                text: 'N',
            },
            {
                dataField: 'name',
                text: 'User Name',
            },
            {
                dataField: 'description',
                text: 'Description',
            },
            {
                dataField: 'type_name',
                text: 'Type',
            },
            // {
            //     dataField: 'following',
            //     text: 'followings',
            // },
            // {
            //     dataField: 'follower',
            //     text: 'Followers',
            // },
            // {
            //     dataField: 'point',
            //     text: 'Points',
            // },
            {
                dataField: 'is_approved',
                text: 'Status',
                formatter: (rowContent, row) => {
                    let status;
                    if (row.is_approved == 1) {
                        status = <span className={"badge badge-success"}>Verified</span>
                    } else if (row.is_approved == -1) {
                        status = <span className={"badge badge-danger"}>Rejected</span>
                    } else {
                        status = <span className={"badge badge-warning"}>Pending</span>
                    }
                    return status;
                }
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (rowContent, row) => {
                    return (
                        <div style={this.state.action_margin_right}>
                            <Link to={`/feed/view?post_id=${row.id}`}>
                                <Button size="sm" color="info" style={this.state.btn_style} className="btn-square active">View</Button>
                            </Link>
                            <Button size="sm" color="danger" onClick={() => this.setState({ showAlert: true, post_id: row.id })} style={this.state.btn_style} className="btn-square active">Block</Button>

                        </div>
                    )
                }
            }];

        return <div >
            <Breadcrumb title="User Feed" parent="Feed" />
            {this.state.showAlert && this.sweetAlert()}
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mb-0">

                            <div className="card-body datatable-react">
                                <ToolkitProvider
                                    keyField="name"
                                    data={data}
                                    columns={columns}
                                // search
                                >
                                    {
                                        props => (
                                            <div >
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                // pagination={paginationFactory(options)}
                                                />
                                                <div className="card-body digits"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row" }}
                                                >
                                                    <i className="icon-angle-left"
                                                        onClick={() => {
                                                            if (this.state.currentPage != 1) {
                                                                this.onPageChanged(this.state.currentPage - 1)
                                                            }
                                                        }}
                                                        style={{ marginRight: 5 }}></i>
                                                    {paginations}
                                                    <i
                                                        onClick={() => {
                                                            if (this.state.currentPage != totalPage) {
                                                                this.onPageChanged(this.state.currentPage + 1)
                                                            }
                                                        }}
                                                        className="icon-angle-right"
                                                    ></i>
                                                </div>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    }

    render() {

        let status;
        if (this.state.user.is_blocked == 1) {
            status = <span className={"badge badge-danger"}>Blocked</span>
        } else if (this.state.user.is_verified == 1) {
            status = <span className={"badge badge-success"}>Verified</span>
        } else {
            status = <span className={"badge badge-warning"}>Not Verified</span>
        }

        return (
            <div>
                <Breadcrumb title="User View" parent="User" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>View User</h5><span>
                                                <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /></span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        First Name</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.first_name}</Label>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Last Name</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.last_name}</Label>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Nick Name</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.nick_name}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Email</Label>
                                                    <Col sm='9'>
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.email}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Phone</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.phone_number}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Bio</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.bio}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Followings</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.nb_following}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Followers</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.nb_follower}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Points</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.point}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Join Date</Label>
                                                    <Col sm="9">
                                                        <Label className="col-sm-9 col-form-label" htmlFor="inputPassword3">{this.state.user.created_at}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Status</Label>
                                                    <Col sm="9">
                                                        <h5>{status}</h5>
                                                    </Col>
                                                </FormGroup>

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Go Back</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.userFeed()}
                </div>
            </div>
        )
    }
}


export default Forms;
