import React from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import GridLayout from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Shake } from "reshake";
//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      data: [],
      arrange_layout: false,
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
      level: 2, // 1 country, 2 topic, 3 sub
      temp_sub: [],
      temp_category: null,
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();

  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getAllUsers(
      this.state.data.length,
      "", "artist"
    );

    if (result.result) {

      this.setState({
        data: result.data.users,
        allItems: result.data.all,
        limit: result.data.limit,
      });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  onUpdateOrder(d) {
    API.updateCategoryOrder(this.state.temp_sub);
    console.log(this.state.temp_sub);
  }

  onEndDrag(item) {
    this.state.temp_sub = [];
    console.log(item);
    let order_number = 1;
    for (let y = 0; y < 100; y++) {
      for (let x = 0; x < 10; x++) {
        item.forEach((i) => {
          if (i.y == y && i.x == x) {
            this.state.temp_sub.push({
              id: i.i,
              order_number: order_number++,
            });
          }
        });
      }
    }
  }

  tableLayout(props, paginations, totalPage) {
    return (
      <div>
        <BootstrapTable
          {...props.baseProps}
          // pagination={paginationFactory(options)}
        />
        <div
          className="card-body digits"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <i
            className="icon-angle-left"
            onClick={() => {
              if (this.state.currentPage != 1) {
                this.onPageChanged(this.state.currentPage - 1);
              }
            }}
            style={{ marginRight: 5 }}
          ></i>
          {paginations}
          <i
            onClick={() => {
              if (this.state.currentPage != totalPage) {
                this.onPageChanged(this.state.currentPage + 1);
              }
            }}
            className="icon-angle-right"
          ></i> */}
        </div>
      </div>
    );
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={
          this.state.temp_category.is_deleted ? "Put Back" : "Delete"
        }
        confirmBtnBsStyle={
          this.state.temp_category.is_deleted ? "info" : "danger"
        }
        type={this.state.temp_category.is_deleted ? "info" : "danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirmBlock}
      >
        {this.state.temp_category.is_delete
          ? "This will put back the feed"
          : "This will remove the Category"}
      </SweetAlert>
    );
  }

  onConfirmBlock = async () => {
    this.hideAlert();

    console.log(this.state.temp_category);

    let form = new FormData();
    form.append("is_deleted", this.state.temp_category.is_deleted ? 0 : 1);
    form.append("category_id", this.state.temp_category.id);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onComfirtBlock = () => {
    this.hideAlert();
    this.toastSuccess();
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  onCheckboxClicked = async (value, item, type) => {
    let form = new FormData();
    form.append(type, value ? 1 : 0);
    form.append("category_id", item.id);

    console.log(value, item, type);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      // this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];

    this.state.data.forEach((d, index) => {
      d.number = ++index;
      data.push(d)
    })

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "nick_name",
        text: "Name",
      },
      {
        dataField: "category_name",
        text: "Category",
      },
      
      {
        dataField: "active",
        text: "Profile",
        formatter: (rowContent, row) => {
          return (
            <img 
              src={API.getImageURL(row.profile_url)}
              style={{width: 50, height: 50}}
            />
          );
        },
      },
      {
        dataField: "tag",
        text: "Tag",
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Link to={`/artist/new?id=${row.user_id}`}>
                <Button
                  size="sm"
                  color="warning"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  Edit
                </Button>
              </Link>
              <Button
                size="sm"
                color={row.is_deleted ? "primary" : "danger"}
                onClick={() =>
                  this.setState({ showAlert: true, temp_category: row })
                }
                style={this.state.btn_style}
                className="btn-square active"
              >
                {row.is_deleted ? "Put Back" : "Delete"}
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="Index" parent="Category" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />

        {/* LIST VIEW BODY */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <Link
                          to={"/artist/new"}
                          style={{ position: "absolute", right: 30 }}
                        >
                          <Button
                            color="info"
                            className="btn-pill btn-info btn-air-info"
                          >
                            New
                          </Button>
                        </Link>

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default Layout;
