import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import API from "../../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../../common/breadcrumb.component';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HexColorPicker } from "react-colorful";

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            text_color: "#fff",
            background_color: "#000",
            active_image: null,
            showAlert: false,
            type_id: 1,
            id: null,
            btn_active_image: null,
            type: [
                {
                    name: "Color",
                    id: 1
                },
                {
                    name: "Gradiant",
                    id: 2
                },
                {
                    name: "Pattern",
                    id: 3
                },
            ]
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }


  getParam(name) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(name);
    }

    async requestOnePattern() {
        
        let result = await API.getOnePattern(this.state.id);

        let data = result.data;

        this.setState({
            text_color: data.text_color,
            background_color: data.background_color,
            active_image: data.image_url,
            type_id: data.type_id
        })
    }

    UNSAFE_componentWillMount() {
        this.state.id = this.getParam("id");
        if (this.state.id) {
            this.requestOnePattern()
        }
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirm}
        >
            This will create new category
    </SweetAlert>
    }

    onConfirm = async () => {
        this.hideAlert();

        let form = new FormData();

        form.append("text_color", this.state.text_color);
        form.append("type_id", this.state.type_id);

        if (this.state.id) {
            form.append("id", this.state.id);
        }

        if (this.state.type_id == 1) {
            form.append("background_color", this.state.background_color);
        } else {
            if (!this.state.active_image || this.state.active_image == "") {
                return alert("Missing Image!")
            }

           try {
            if (!this.state.active_image.includes("upload")) {
                form.append("image_url", this.state.active_image);
            }
           } catch (error) {
            form.append("image_url", this.state.active_image);
           }
        }


        let result = await API.updatePattern(form);

        if (result.result) {
            this.toastSuccess();
            this.props.history.goBack();
        } else {
            this.toastFail(result.message)
        }
    }

    onImageChange(event, type) {
        // Assuming only image
        this.state.active_image = event.target.files[0]
        this.setState({})
    }

    render() {

        let image = this.state.active_image || "";

        if (image && image !== "") {
            try {
                if (image.includes("upload")) {
                    image = API.getImageURL(image);
                }
            } catch (error) {
                image = URL.createObjectURL(image)
            }
        }

        return (
            <div>
                <Breadcrumb title="Pattern New" parent="Pattern" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Pattern</h5><span>
                                                {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Type</Label>
                                                    <Col sm="9">
                                                        <select className="col-sm-9 form-control" onChange={(e) => this.setState({type_id: e.target.value})}>
                                                            {this.state.type.map((type, index) => <option selected={this.state.type_id == type.id} value={type.id}>{type.name}</option>)}
                                                        </select>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Preview</Label>
                                                    <Col sm="9">
                                                        {
                                                            this.state.type_id == 1 &&
                                                            <div 
                                                            style={{
                                                                width: 100, height: 100, 
                                                                backgroundColor: this.state.background_color,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                           
                                                        </div>
                                                        }
                                                        {
                                                            (this.state.type_id == 2 || this.state.type_id == 3 )&&
                                                            <img
                                                                src={image}
                                                                // className="img-100 m-l-35"

                                                                style={{width: 100, height: 100}}
                                                            />
                                                        }
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: 40,
                                                                left: 45,
                                                                color: this.state.text_color, 
                                                                alignSelf: 'center'
                                                            }}   
                                                        >
                                                            Hello
                                                           </span>
                                                    </Col>
                                                </FormGroup>

                                                {
                                                    this.state.type_id == 1 &&
                                                    <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Background Color</Label>
                                                    <Col sm="9">
                                                        <HexColorPicker color={this.state.background_color} onChange={(newColor) => this.setState({background_color: newColor})} />
                                                    </Col>
                                                </FormGroup>
                                                }
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Text Color</Label>
                                                    <Col sm="9">
                                                        <HexColorPicker color={this.state.text_color} onChange={(newColor) => this.setState({text_color: newColor})} />
                                                    </Col>
                                                </FormGroup>


                                                
                                                {
                                                    (this.state.type_id == 3 || this.state.type_id == 2) &&
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Image</Label>
                                                        <Col sm="3">
                                                            <Button color="info" type="button" onClick={() => this.state.btn_active_image.click()} className="btn-pill btn-info btn-air-info">Choose Image</Button>
                                                            <input ref={(ref) => this.state.btn_active_image = ref} style={{ display: "none" }} type="file" onChange={(e) => this.onImageChange(e, 1)} />
                                                        </Col>

                                                    </FormGroup>
                                                }

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                            <Button onClick={() => this.setState({ showAlert: true })} color='primary'>{this.state.id? "Edit" : "Create" }</Button>{' '}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
