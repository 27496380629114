import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Col } from "reactstrap";
import API from "../../request/api";
import "react-day-picker/lib/style.css";
// import Custom Componenets
import Breadcrumb from "../common/breadcrumb.component";
import { Link } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Forms extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.state = {
      item: {},
      showAlert: false,
      btn_active_image: null,
      btn_inactive_image: null,
    };
  }
  handleDayChange(day) {
    // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
    this.state.user.created_at = day;
    this.setState({});
  }

  getParam(name) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(name);
  }

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    let result = await API.getOneCategory(this.getParam("category_id"));
    if (result.result) {
      this.setState({ item: result.data });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText="Yes, I do!"
        confirmBtnBsStyle={"warning"}
        type={"warning"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onComfirm}
      >
        This will create new category
      </SweetAlert>
    );
  }

  onComfirm = () => {
    this.hideAlert();
    this.toastSuccess();
    this.props.history.goBack();
  };

  onImageChange(event, type) {
    // Assuming only image
    if (type == 1) {
      this.state.item.active_image_url = null;
      this.state.item.active_image = event.target.files[0];
    } else {
      this.state.item.inactive_image_url = null;
      this.state.item.inactive_image = event.target.files[0];
    }

    this.setState({});
  }

  render() {
    let activeImageSrc;
    if (this.state.item.active_image_url) {
      activeImageSrc = this.state.item.active_image_url.includes("upload/")
        ? API.serverUrl() + this.state.item.active_image_url
        : this.state.item.active_image_url;
    } else if (this.state.item.active_image) {
      activeImageSrc = URL.createObjectURL(this.state.item.active_image);
    } else {
      activeImageSrc = "";
    }

    let inactiveImageSrc;
    if (this.state.item.inactive_image_url) {
      inactiveImageSrc = this.state.item.inactive_image_url.includes("upload/")
        ? API.serverUrl() + this.state.item.inactive_image_url
        : this.state.item.inactive_image_url;
    } else if (this.state.item.inactive_image) {
      inactiveImageSrc = URL.createObjectURL(this.state.item.inactive_image);
    } else {
      inactiveImageSrc = "";
    }
    return (
      <div>
        <Breadcrumb title="Category View" parent="Category" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xl-6">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>View Category</h5>
                      <span>
                        {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                      </span>
                    </div>
                    <div className="card-body">
                      <Form className="theme-form">
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            English
                          </Label>
                          <Col sm="9">
                            <Col sm="9">
                              <Label
                                className="col-sm-9 col-form-label"
                                htmlFor="inputPassword3"
                              >
                                Khmer Song
                              </Label>
                            </Col>
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Followers
                          </Label>
                          <Col sm="9">
                            <Label
                              className="col-sm-9 col-form-label m-l-15"
                              htmlFor="inputPassword3"
                            >
                              100
                            </Label>
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Description
                          </Label>
                          <Col sm="9">
                            <Label
                              className="col-sm-9 col-form-label m-l-15"
                              htmlFor="inputPassword3"
                            >
                              Feel the music here
                            </Label>
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Topic
                          </Label>
                          <Col sm="9">
                            <Label
                              className="col-sm-9 col-form-label m-l-15"
                              htmlFor="inputPassword3"
                            >
                              Music
                            </Label>
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Category
                          </Label>
                          <Col sm="9">
                            <Label
                              className="col-sm-9 col-form-label m-l-15"
                              htmlFor="inputPassword3"
                            >
                              Pop
                            </Label>
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Owner
                          </Label>
                          <Col sm="9">
                          <Link to={`/user/view?user_id=1`}>
                          <Label
                              className="col-sm-9 col-form-label m-l-15"
                              htmlFor="inputPassword3"
                            >
                              Kuoy Suong
                            </Label>
                            </Link>
                            
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Profile Image
                          </Label>

                          <Col sm="6">
                            <img
                              src={
                                "https://media.istockphoto.com/vectors/music-note-icon-vector-illustration-vector-id1175435360?k=6&m=1175435360&s=612x612&w=0&h=zuR82fJ-cZQpPZeIaUXAcQZ_jAXaVOSt-sWB6ycDpy0="
                              }
                              className="img-100 m-l-35"
                              // style={{width: 300, height: 300}}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputPassword3"
                          >
                            Cover Image
                          </Label>
                          <Col sm="6">
                            <img
                              src={
                                "https://411press.net/wp-content/uploads/2020/06/music.png"
                              }
                              className="img-100 m-l-35"
                              // style={{width: 300, height: 300}}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="card-footer">
                      {/* <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '} */}
                      <Button
                        onClick={() => this.props.history.goBack()}
                        color="secondary"
                      >
                        Go Back
                      </Button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forms;
