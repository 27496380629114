import React from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import GridLayout from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Shake } from "reshake";
//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      actionIndex: null,
      selectItem: null,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      data: [],
      arrange_layout: false,
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
      level: 2, // 1 country, 2 topic, 3 sub
      temp_sub: [],
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();

  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getAllRegisterOnlineClassMember(
      this.state.data.length,
    );

    console.log(result)

    if (result.result) {

      this.setState({
        data: result.data.users,
        allItems: result.data.all,
        limit: result.data.limit,
      });
    } else {
      this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  onUpdateOrder(d) {
    API.updateCategoryOrder(this.state.temp_sub);
    console.log(this.state.temp_sub);
  }

  onEndDrag(item) {
    this.state.temp_sub = [];
    console.log(item);
    let order_number = 1;
    for (let y = 0; y < 100; y++) {
      for (let x = 0; x < 10; x++) {
        item.forEach((i) => {
          if (i.y == y && i.x == x) {
            this.state.temp_sub.push({
              id: i.i,
              order_number: order_number++,
            });
          }
        });
      }
    }
  }

  tableLayout(props, paginations, totalPage) {
    return (
      <div>
        <BootstrapTable
          {...props.baseProps}
          // pagination={paginationFactory(options)}
        />
        <div
          className="card-body digits"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <i
            className="icon-angle-left"
            onClick={() => {
              if (this.state.currentPage != 1) {
                this.onPageChanged(this.state.currentPage - 1);
              }
            }}
            style={{ marginRight: 5 }}
          ></i>
          {paginations}
          <i
            onClick={() => {
              if (this.state.currentPage != totalPage) {
                this.onPageChanged(this.state.currentPage + 1);
              }
            }}
            className="icon-angle-right"
          ></i> */}
        </div>
      </div>
    );
  }

  sweetAlert() {

    let status = [
      {
        button: "Accept",
        style: "success",
        action: "This will let user post online class!",
      },
      {
        button: "Reject",
        style: "danger",
        action: "This will reject user from posting online class!"
      },
      {
        button: "Close",
        style: "info",
        action: "ID Card of user"
      }
      
    ];

    return (
      <SweetAlert
        style={{
          width: "50%", height: "60%"
        }}
        showCancel={this.state.actionIndex != 2}
        confirmBtnText={
          status[this.state.actionIndex].button
        }
        confirmBtnBsStyle={
          status[this.state.actionIndex].style
        }
        type={status[this.state.actionIndex].style}
        title={this.state.actionIndex != 2? "Are you sure?" : "ID Card"}
        onCancel={this.hideAlert}
        onConfirm={this.onConfirm}
      >
        {/* {status[this.state.actionIndex].action} */}

        <img 
          style={{width: 600, height: 300, marginTop: 0}}
          src={API.getImageURL(this.state.selectItem.id_card_url)}
        />

      </SweetAlert>
    );
  }

  onConfirm = async () => {
    this.hideAlert();

    if (this.state.actionIndex == 2) {
      return;
    }

    let status = this.state.actionIndex == 0? 2 : -1

    let result = await API.updateRegisterOnlineClassMember({status_id: status, user_id: this.state.selectItem.user_id});

    if (result.result) {
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  onCheckboxClicked = async (value, item, type) => {
    let form = new FormData();
    form.append(type, value ? 1 : 0);
    form.append("category_id", item.id);

    console.log(value, item, type);

    let result = await API.updateCategory(form);

    if (result.result) {
      this.toastSuccess();
      // this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];

    this.state.data.forEach((d, index) => {
      d.number = ++index;
      data.push(d)
    })

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "full_name",
        text: "Full Name",
        formatter: (rowContent, row) => {
          return (
            <Link to={`/user/view?user_id=${row.user_id}`}>
                {row.full_name}
              </Link>
            
          );
        },
      },
      // {
      //   dataField: "bio",
      //   text: "Bio",
      // },
      {
        dataField: "work_place",
        text: "Work Place",
      },
      {
        dataField: "education",
        text: "Education",
      },
      {
        dataField: "phone_number",
        text: "Phone Number",
      },
      // {
      //   dataField: "category_name",
      //   text: "Category",
      // },
      
      {
        dataField: "active",
        text: "ID Card",
        formatter: (rowContent, row) => {
          return (
            <div
              onClick={() =>
                this.setState({ showAlert: true, selectItem: row, actionIndex: 2 })
              }
                  // size="sm"
                  // color="white"
                  // style={this.state.btn_style}
                  // className="btn-square "
                >
                  <img 
                    src={API.getImageURL(row.id_card_url)}
                    style={{width: 70, height: 50}}
                  />
                </div>
            
          );
        },
      },

      {
        dataField: "is_approved",
        text: "Status",
        formatter: (rowContent, row) => {
          let status;
          if (row.status_id == 1) {
            status = <span className={"badge badge-info"}>Pending</span>;
          } else if (row.status_id == 2) {
            status = <span className={"badge badge-success"}>Approved</span>;
          } else {
            status = <span className={"badge badge-danger"}>Rejected</span>;
          }
          return status;
        },
      },

      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Button
              onClick={() =>
                this.setState({ showAlert: true, selectItem: row, actionIndex: 0 })
              }
                  size="sm"
                  color="success"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  Accept
                </Button>
              <Button
                size="sm"
                color={"danger"}
                onClick={() =>
                  this.setState({ showAlert: true, selectItem: row, actionIndex: 1 })
                }
                style={this.state.btn_style}
                className="btn-square active"
              >
                Reject
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="Index" parent="Register Member" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />

        {/* LIST VIEW BODY */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        {/* <Link
                          to={"/artist/new"}
                          style={{ position: "absolute", right: 30 }}
                        >
                          <Button
                            color="info"
                            className="btn-pill btn-info btn-air-info"
                          >
                            New
                          </Button>
                        </Link> */}

                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default Layout;
