import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import API from "../../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../../common/breadcrumb.component';
import CKEditors from "react-ckeditor-component";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            item: {
                id: 1,
                title: "",
                description: "",
                image_url: "",
            },
            showAlert: false,
            btn_active_image: null,
            btn_inactive_image: null,
           
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    componentWillMount() {
        this.state.item.id = API.getParam("id");
        this.requestOneAnnouncement();
    }

    async requestOneAnnouncement() {
        let result = await API.getOneAnnouncement(this.state.item.id);
        this.setState({item: result.data});
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirm}
        >
            This will create new category
    </SweetAlert>
    }

    onConfirm = async () => {
        this.hideAlert();
        

        let form = new FormData();

        form.append("id", this.state.item.id);
        form.append("title", this.state.item.title);
        form.append("description", this.state.item.description);
        if (this.state.item.upload) {
            form.append("image_url", this.state.item.upload);
        }

        let result = await API.updateAnnouncement(form);

        if (result.result) {
            this.toastSuccess();
            this.props.history.goBack();
        } else {
            this.toastFail(result.message)
        }
    }

    onImageChange(event, type) {
        // Assuming only image
        this.state.item.upload = event.target.files[0];
        this.setState({})
    }

    onBlur(evt) {
        // console.log("onBlur event called with event info: ", evt);
    }

    afterPaste(evt) {
        // console.log("afterPaste event called with event info: ", evt);
    }
    onChange = (evt) => {
        var newContent = evt.editor.getData();
        this.state.item.description = newContent
        this.setState({})

    }

    render() {
        return (
            <div>
                <Breadcrumb title="Announcement Edit" parent="Announcement" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Edit Announcement</h5><span>
                                                {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Title</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.item.title = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.item.title}
                                                            className="form-control" type="text" placeholder="English Name" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Image</Label>
                                                    <Col sm="3">
                                                        <Button color="info" type="button" onClick={() => this.state.btn_active_image.click()} className="btn-pill btn-info btn-air-info">Choose Image</Button>
                                                        <input ref={(ref) => this.state.btn_active_image = ref} style={{ display: "none" }} type="file" onChange={(e) => this.onImageChange(e, 1)} />
                                                    </Col>
                                                    <Col sm="6">
                                                        <img
                                                            src={this.state.item.upload ? URL.createObjectURL(this.state.item.upload) : API.getImageURL(this.state.item.image_url)}
                                                            className="img-100 m-l-35"
                                                        // style={{width: 300, height: 300}}
                                                        />
                                                    </Col>

                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Description</Label>
                                                        <CKEditors
                                                            activeClass="p10"
                                                            content={this.state.item.description }
                                                            events={{
                                                                "blur": this.onBlur,
                                                                "afterPaste": this.afterPaste,
                                                                "change": this.onChange
                                                            }}
                                    />
                                                </FormGroup>

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '}
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
