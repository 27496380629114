import React from "react";
import Breadcrumb from "../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      limit: 15,
      allItems: 150,
      currentPage: 1,
      data: [],
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText="Block"
        confirmBtnBsStyle={"danger"}
        type={"danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onComfirtBlock}
      >
        This user can't login anymore
      </SweetAlert>
    );
  }

  onComfirtBlock = () => {
    this.hideAlert();
    this.toastSuccess();
  };

  onPageChanged(page) {
    this.setState({ currentPage: page });
  }

  onSave = async () => {
    let data = {
      data: this.state.data,
    };

    let result = await API.updatePostActionType(data);
    if (result.result) {
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    this.state.data = [];
    let result = await API.getPostActionType();
    if (result.result) {
      result.data.forEach((d) => {
        this.state.data.push(d);
      });
      this.setState({});
    } else {
      this.toastFail(result.message);
    }
  }

  onValueChange = (value, row) => {
    this.state.data.forEach((d) => {
      if (row.id === d.id) {
        d.point = parseInt(value);
        
      }
    });
    this.setState({});
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "N",
      },
      {
        dataField: "name",
        text: "Type",
      },
      {
        dataField: "point",
        text: "Point",
        formatter: (rowContent, row) => {
          return (
            <input
              type="number"
              defaultValue={row.point}
              onChange={(e) => this.onValueChange(e.target.value, row)}
            />
          );
        },
      },
      {
        dataField: "is_limited",
        text: "Limit Per Day",
        formatter: (rowContent, row) => {
          return <input disabled={row.id === 1} type="checkbox" defaultChecked={row.is_limited} onChange={(event) => row.is_limited = event.target.checked} value={row.is_limited} />
      }
      },
    ];

    return (
      <div>
        <Breadcrumb title="Point Setting" parent="Setting" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <h5>
                    When User Starts interaction with post, they will get point
                    as setting below (They can get only once when interact)
                  </h5>
                </div>

                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={this.state.data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable {...props.baseProps} />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        ></div>
                      </div>
                    )}
                  </ToolkitProvider>
                  <Button
                    size="sm"
                    color="info"
                    style={{ marginTop: 10 }}
                    onClick={this.onSave}
                    className="btn-square active"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Layout;
