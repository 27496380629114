import React from 'react';
import Breadcrumb from '../common/breadcrumb.component';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button } from 'reactstrap';
import API from "../../request/api";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//json file

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            limit: 15,
            allItems: 150,
            currentPage: 1,
            data: [],
            btn_style: {
                marginRight: 10,
            },
            action_margin_right: {
                marginRight: 0
            }
        };
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Block"
            confirmBtnBsStyle={"danger"}
            type={"danger"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onComfirtBlock}
        >
            This user can't login anymore
    </SweetAlert>
    }

    onComfirtBlock = () => {
        this.hideAlert();
        this.toastSuccess()
    }

    onPageChanged(page) {
        this.setState({ currentPage: page })
    }

    UNSAFE_componentWillMount() {
        this.requestData()
    }

    async requestData() {
        let result = await API.getSetting("Auto Approval");
        if (result.result) {
            this.setState({ data: [result.data] })
        } else {
            this.toastFail(result.message);
        }
    }

    onCheckboxClicked = async (value, item) => {
        let data = {
            active: value,
            type: "Auto Approval"
        }
        let result = await API.updateSetting(data);
        if (result.result) {
            this.toastSuccess();
        } else {
            this.toastFail(result.message)
        }
    }


    render() {
        let totalPage = this.state.allItems / this.state.limit;
        let paginations = [];
        for (let i = 1; i <= totalPage; i++) {

            paginations.push(<a key={i} href="#" onClick={() => this.onPageChanged(i)} className={"badge badge-" + (this.state.currentPage == i ? "info" : "light")}>{i}</a>)
        }
        var data = [];

        this.state.data.forEach((d, index) => {
            d.number = this.state.currentPage * index + 1;
            data.push(d);
        })
        const columns = [
            {
                dataField: 'number',
                text: 'N',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'active',
                text: 'Status',
                formatter: (rowContent, row) => {
                    return <input type="checkbox" defaultChecked={row.active} onChange={(event) => this.onCheckboxClicked(event.target.checked ,row)} value={row.active} />
                }
            },
            {
                dataField: 'description_en',
                text: 'Description',
            },
        ];

        return <div >
            <Breadcrumb title="Other" parent="Setting" />
            {this.state.showAlert && this.sweetAlert()}
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mb-0">

                            <div className="card-body datatable-react">
                                <ToolkitProvider
                                    keyField="name"
                                    data={data}
                                    columns={columns}
                                // search
                                >
                                    {
                                        props => (
                                            <div >
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                />
                                                <div className="card-body digits"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row" }}
                                                >

                                                </div>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    }
}

export default Layout;