import React from "react";
import Breadcrumb from "../../common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";
import API from "../../../request/api";
import SweetAlert from "react-bootstrap-sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//json file

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      limit: 15,
      allItems: 0,
      currentPage: 1,
      data: [],
      post_id: 0,
      order_by: null,
      post_type_id: 0,
      btn_style: {
        marginRight: 10,
      },
      action_margin_right: {
        marginRight: 0,
      },
    };
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  UNSAFE_componentWillMount() {
    this.requestData();
  }

  async requestData() {
    this.setState({
      data: [],
    });
    let result = await API.getAllPosts((this.state.currentPage - 1) * this.state.limit, this.state.order_by, this.state.post_type_id);
    if (result.result) {
        this.setState({
            data: result.data.posts,
            allItems: result.data.all,
            limit: result.data.limit,
        })

    } else {
        this.toastFail(result.message);
    }
  }

  toastSuccess() {
    toast.success("Action Successful !");
  }

  toastFail(text) {
    toast.error(text);
  }

  sweetAlert() {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={this.state.post_id.is_approved == -1? "Unblock" : "Block"}
        confirmBtnBsStyle={this.state.post_id.is_approved == -1? "info" : "danger"}
        type={this.state.post_id.is_approved == -1? "info" : "danger"}
        title="Are you sure?"
        onCancel={this.hideAlert}
        onConfirm={this.onConfirmBlock}
      >
        {this.state.post_id.is_approved == -1? "This will put back the feed" : "This will remove the feed"}
      </SweetAlert>
    );
  }

  onConfirmBlock = async () => {
    this.hideAlert();
    let data = {
      is_approved: this.state.post_id.is_approved == -1? 1 : -1,
      post_id: this.state.post_id.id,
    };
    let result = await API.approvePost(data);
    if (result.result) {
      this.setState({ data: [] });
      this.toastSuccess();
      this.requestData();
    } else {
      this.toastFail(result.message);
    }
  };

  onPageChanged(page) {
    this.requestData();
    this.setState({ currentPage: page });
  }

  onTypeChange(event) {
    this.state.post_type_id = event.target.value;
    this.requestData()
  }

  onOrderChange(event) {
    this.state.order_by = event.target.value;
    this.requestData()
  }

  render() {
    let totalPage = Math.ceil(this.state.allItems / this.state.limit);
    let paginations = [];
    for (let i = 1; i <= totalPage; i++) {
      paginations.push(
        <a
          key={i}
          href="#"
          onClick={() => this.onPageChanged(i)}
          className={
            "badge badge-" + (this.state.currentPage == i ? "info" : "light")
          }
        >
          {i}
        </a>
      );
    }
    var data = [];
    this.state.data.forEach((d, index) => {
      d.number = this.state.currentPage * index + 1;
      data.push({
        id: d.post_original_id,
        name: d.user.name,
        description: d.description,
        type_name: d.type_name,
        is_approved: d.is_approved,
        number: d.number,
        type: d.type,

        nb_like: d.nb_like || 0,
        nb_unlike: d.nb_unlike || 0,
        nb_comment: d.nb_comment || 0,
        nb_share: d.nb_share || 0,
        nb_vote_up: d.nb_vote_up || 0,
        nb_vote_down: d.nb_vote_down || 0,
      });
    });
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "number",
        text: "N",
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "description",
        text: "Description",
        formatter: (rowContent, row) => {
          if (row.description.length > 20) {
            row.description = row.description.substring(0, 20) + "..."
          }
          return <span>{row.description}</span>;
        },
      },
      {
        dataField: "nb_like",
        text: "Like",
      },
      {
        dataField: "nb_unlike",
        text: "Unlike",
      },
      {
        dataField: "nb_comment",
        text: "Comment",
      },
      {
        dataField: "nb_share",
        text: "Share",
      },
      {
        dataField: "nb_vote_up",
        text: "Vote Up",
      },
      {
        dataField: "nb_vote_down",
        text: "Vote Down",
      },
      {
        dataField: "type_name",
        text: "Type",
      },
      {
        dataField: "is_approved",
        text: "Status",
        formatter: (rowContent, row) => {
          let status;
          if (row.is_approved == 1) {
            status = <span className={"badge badge-success"}>Verified</span>;
          } else if (row.is_approved == -1) {
            status = <span className={"badge badge-danger"}>Removed</span>;
          } else {
            status = <span className={"badge badge-warning"}>Pending</span>;
          }
          return status;
        },
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (rowContent, row) => {
          return (
            <div style={this.state.action_margin_right}>
              <Link to={`/feed/view?post_id=${row.id}`}>
                <Button
                  size="sm"
                  color="info"
                  style={this.state.btn_style}
                  className="btn-square active"
                >
                  View
                </Button>
              </Link>
              <Button
                size="sm"
                color={row.is_approved == -1? "primary" : "danger"}
                onClick={() =>
                  this.setState({ showAlert: true, post_id: row })
                }
                style={this.state.btn_style}
                className="btn-square active"
              >
                {row.is_approved == -1? "Put Back" : "Remove"}
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Breadcrumb title="Index" parent="Feed" />
        {this.state.showAlert && this.sweetAlert()}
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="name"
                    data={data}
                    columns={columns}
                    // search
                  >
                    {(props) => (
                      <div>
                        <SearchBar
                          style={{ width: 300 }}
                          {...props.searchProps}
                        />
                        <select
                          className="custom-select form-control"
                          style={{
                            // marginBottom: 10,
                            marginRight: 12,
                            marginLeft: 12,
                            width: 200,
                            position: "absolute",
                          }}
                          onChange={(e) => this.onOrderChange(e)}
                        >
                          <option value="null">Latest</option>
                          <option value="like">Like</option>
                          <option value="unlike">Unlike</option>
                          <option value="comment">Comment</option>
                          <option value="share">Share</option>
                          <option value="vote_up">Vote Up</option>
                          <option value="vote_down">Vote Down</option>
                        </select>

                        <select
                          className="custom-select form-control"
                          style={{
                            // marginBottom: 10,
                            marginRight: 12,
                            marginLeft: 12,
                            width: 200,
                            left: 540,
                            position: "absolute",
                          }}
                          onChange={(e) => this.onTypeChange(e)}
                        >
                          <option value="0">All Type</option>
                          <option value="6">Online Shop</option>
                          <option value="5">Online Class</option>
                          <option value="4">Link</option>
                          <option value="3">Voice Slide</option>
                          <option value="2">Video</option>
                          <option value="1">Photo</option>
                        </select>
                        <BootstrapTable
                          {...props.baseProps}
                          // pagination={paginationFactory(options)}
                        />
                        <div
                          className="card-body digits"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <i
                            className="icon-angle-left"
                            onClick={() => {
                              if (this.state.currentPage != 1) {
                                this.onPageChanged(this.state.currentPage - 1);
                              }
                            }}
                            style={{ marginRight: 5 }}
                          ></i>
                          {paginations}
                          <i
                            onClick={() => {
                              if (this.state.currentPage != totalPage) {
                                this.onPageChanged(this.state.currentPage + 1);
                              }
                            }}
                            className="icon-angle-right"
                          ></i>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Layout;
