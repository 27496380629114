import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import API from "../../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../../common/breadcrumb.component';

import SweetAlert from 'react-bootstrap-sweetalert';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            item: {
                id: 1,
                title: "",
                website: "",
                image_url: "",
                start_date: "",
                end_date: ""
            },
            showAlert: false,
            btn_active_image: null,
            btn_inactive_image: null,
           
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    componentWillMount() {

        this.state.item.id = API.getParam("id");

        this.requestOneAds();
    }

    async requestOneAds() {
        let result = await API.getOneAds(this.state.item.id);

        // result.data.start_date = new Date(result.data.start_date)
        // result.data.end_date = new Date(result.data.end_date)

        this.setState({item: result.data})
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirm}
        >
            This will create new Ads
    </SweetAlert>
    }

    onConfirm = async () => {
        this.hideAlert();

        if (!this.state.item.title) {
            alert("not enough information!")
            return;
        }  

        let form = new FormData();

        form.append("id", this.state.item.id);
        form.append("title", this.state.item.title);
        form.append("website", this.state.item.website);
        if (this.state.item.upload) {
            form.append("image_url", this.state.item.upload);
        }
        form.append("start_date",  moment(this.state.item.start_date).format('DD-MM-YYYY'));
        form.append("end_date", moment(this.state.item.end_date).format('DD-MM-YYYY'));

        let result = await API.updateAds(form);

        if (result.result) {
            this.toastSuccess();
            this.props.history.goBack();
        } else {
            this.toastFail(result.message)
        }
    }

    onImageChange(event, type) {
        // Assuming only image
        this.state.item.upload = event.target.files[0];
        this.setState({})
    }

    render() {
        return (
            <div>
                <Breadcrumb title="Ads View" parent="Ads" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5> Ads</h5><span>
                                                {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Title</Label>
                                                    <Col sm="9">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        {this.state.item.title}</Label>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Website</Label>
                                                    <Col sm="9">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        {this.state.item.website}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Ad Image</Label>
                                                    {/* <Col sm="3">
                                                        <Button color="info" type="button" onClick={() => this.state.btn_active_image.click()} className="btn-pill btn-info btn-air-info">Choose Image</Button>
                                                        <input ref={(ref) => this.state.btn_active_image = ref} style={{ display: "none" }} type="file" onChange={(e) => this.onImageChange(e, 1)} />
                                                    </Col> */}
                                                    <Col sm="6">
                                                        <img
                                                            src={this.state.item.upload ? URL.createObjectURL(this.state.item.upload) : API.getImageURL(this.state.item.image_url)}
                                                            className="img-100 m-l-35"
                                                        // style={{width: 300, height: 300}}
                                                        />
                                                    </Col>

                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Start Date</Label>
                                                    <Col sm="9">
                                                    <Label className="col-sm-6 col-form-label" htmlFor="inputPassword3">
                                                        {this.state.item.start_date}</Label>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        End Date</Label>
                                                    <Col sm="9">
                                                    <Label className="col-sm-6 col-form-label" htmlFor="inputPassword3">
                                                        {this.state.item.end_date}</Label>
                                                    </Col>
                                                </FormGroup>

                                               
                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            {/* <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '} */}
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
