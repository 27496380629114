export function postRequest(url, body) {
    return fetch(url, {
        method: 'POST',
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
            token: localStorage.getItem("token"),
            language: "en"
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            return response.json();
        })

}

