import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// ** Import custom components **
import store from './store';
import App from './components/app';

// general
import Dashboard from './components/general/dashboard';

// Country
import CountryIndex from './components/general/category/country/index';
import CountryNew from './components/general/category/country/new';
import CountryEdit from './components/general/category/country/edit';
import CountryView from './components/general/category/country/view';

// City
import CityIndex from './components/general/category/city/index';
import CityNew from './components/general/category/city/new';
import CityEdit from './components/general/category/city/edit';
import CityView from './components/general/category/city/view';

// Topic
import TopicMainIndex from './components/general/category/topic/index';
import TopicMainNew from './components/general/category/topic/new';
import TopicMainEdit from './components/general/category/topic/edit';
import TopicMainView from './components/general/category/topic/view';

// Page
import PageIndex from './components/page/index';
import PageView from './components/page/view';

// Category
import CategoryIndex from './components/general/category/category/index';
import CategoryNew from './components/general/category/category/new';
import CategoryEdit from './components/general/category/category/edit';
import CategoryView from './components/general/category/category/view';

// pattern
import PatternIndex from './components/general/category/pattern/index';
import PatternNew from './components/general/category/pattern/new';
import PatternEdit from './components/general/category/pattern/edit';
import PatternView from './components/general/category/pattern/view';

// Subcategory
import CategorySubIndex from './components/general/category/sub/index';
import CategorySubNew from './components/general/category/sub/new';
import CategorySubEdit from './components/general/category/sub/edit';
import CategorySubView from './components/general/category/sub/view';

// Subcategory
import TitleIndex from './components/general/category/title/index';
import TitleNew from './components/general/category/title/new';
import TitleEdit from './components/general/category/title/edit';
import TitleView from './components/general/category/title/view';

// Feed
import FeedIndex from './components/general/feed/index';
import FeedView from './components/general/feed/view';

// user
import UserIndex from './components/general/user/index';
import UserView from './components/general/user/view';
import UserEdit from './components/general/user/edit';

// artist
import ArtistIndex from './components/general/artist/index';
import ArtistNew from './components/general/artist/new';

// Online Class Register
import OnlineClassRegisterIndex from './components/general/online_class_register/index';

//report type
import ReportTypeIndex from './components/report/report_type/index';
import ReportTypeNew from './components/report/report_type/new';
import ReportTypeEdit from './components/report/report_type/edit';
import ReportTypeView from './components/report/report_type/view';

// ads & announcement
import AdsIndex from './components/general/ads/ads/index';
import AdsNew from './components/general/ads/ads/new';
import AdsEdit from './components/general/ads/ads/edit';
import AdsView from './components/general/ads/ads/view';

import AnnouncementIndex from './components/general/ads/announcement/index';
import AnnouncementNew from './components/general/ads/announcement/new';
import AnnouncementEdit from './components/general/ads/announcement/edit';
import AnnouncementView from './components/general/ads/announcement/view';

//report 
import ReportIndex from './components/report/report/index';
import ReportView from './components/report/report/view';

//seting
import Policy from './components/setting/policy';
import AboutUs from './components/setting/about_us';
import OnlineClassTips from './components/setting/class_tips';
import OnlineShopTips from './components/setting/shop_tips';
import PointUsage from './components/setting/point_usage';
import HelpContent from './components/setting/help_content';
import AdsBanner from './components/setting/ads_banner';
import FooterTone from './components/setting/footer_tone';
import Other from './components/setting/other';
import PointSetting from './components/setting/pointSetting';
import AppShare from './components/setting/app_share';
import AdsPopUp from './components/setting/ads_pop_up';
import ChangeLogMobile from './components/change_log/change_log_mobile';
import ChangeLogWebsite from './components/change_log/change_log_website';
import ChangeLogBackend from './components/change_log/change_log_backend';
import ChangeLogServer from './components/change_log/change_log_server';
import APK from './components/setting/apk';

// errer
import Error404 from './components/error/error-404';

//auth
import Login from './components/auth/login';

class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>

                            <Route path={`${process.env.PUBLIC_URL}/pages/error-404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />

                            <App>
                                <Route exact path={`${process.env.PUBLIC_URL}`} component={Dashboard} />

                                <Route path={`${process.env.PUBLIC_URL}/user/index`} component={UserIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/user/view`} component={UserView} />
                                <Route path={`${process.env.PUBLIC_URL}/user/edit`} component={UserEdit} />

                                <Route path={`${process.env.PUBLIC_URL}/artist/new`} component={ArtistNew} />
                                <Route path={`${process.env.PUBLIC_URL}/artist/index`} component={ArtistIndex} />

                                <Route path={`${process.env.PUBLIC_URL}/online_class_register/index`} component={OnlineClassRegisterIndex} />

                                <Route path={`${process.env.PUBLIC_URL}/feed/index`} component={FeedIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/feed/view`} component={FeedView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/country/index`} component={CountryIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/country/new`} component={CountryNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/country/edit`} component={CountryEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/country/view`} component={CountryView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/topic/index`} component={TopicMainIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/topic/new`} component={TopicMainNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/topic/edit`} component={TopicMainEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/topic/view`} component={TopicMainView} />

                                <Route path={`${process.env.PUBLIC_URL}/report_type/index`} component={ReportTypeIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/report_type/new`} component={ReportTypeNew} />
                                <Route path={`${process.env.PUBLIC_URL}/report_type/edit`} component={ReportTypeEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/report_type/view`} component={ReportTypeView} />

                                <Route path={`${process.env.PUBLIC_URL}/ads/index`} component={AdsIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/ads/new`} component={AdsNew} />
                                <Route path={`${process.env.PUBLIC_URL}/ads/edit`} component={AdsEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/ads/view`} component={AdsView} />

                                <Route path={`${process.env.PUBLIC_URL}/announcement/index`} component={AnnouncementIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/announcement/new`} component={AnnouncementNew} />
                                <Route path={`${process.env.PUBLIC_URL}/announcement/edit`} component={AnnouncementEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/announcement/view`} component={AnnouncementView} />

                                <Route path={`${process.env.PUBLIC_URL}/report/index`} component={ReportIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/report/view`} component={ReportView} />

                                <Route path={`${process.env.PUBLIC_URL}/page/index`} component={PageIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/page/view`} component={PageView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/category/index`} component={CategoryIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/category/new`} component={CategoryNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/category/edit`} component={CategoryEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/category/view`} component={CategoryView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/pattern/index`} component={PatternIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/pattern/new`} component={PatternNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/pattern/edit`} component={PatternEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/pattern/view`} component={PatternView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/sub/index`} component={CategorySubIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/sub/new`} component={CategorySubNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/sub/edit`} component={CategorySubEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/sub/view`} component={CategorySubView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/title/index`} component={TitleIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/title/new`} component={TitleNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/title/edit`} component={TitleEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/title/view`} component={TitleView} />

                                <Route path={`${process.env.PUBLIC_URL}/category/city/index`} component={CityIndex} />
                                <Route path={`${process.env.PUBLIC_URL}/category/city/new`} component={CityNew} />
                                <Route path={`${process.env.PUBLIC_URL}/category/city/edit`} component={CityEdit} />
                                <Route path={`${process.env.PUBLIC_URL}/category/city/view`} component={CityView} />

                                <Route path={`${process.env.PUBLIC_URL}/other`} component={Other} />
                                <Route path={`${process.env.PUBLIC_URL}/point_setting`} component={PointSetting} />
                                <Route path={`${process.env.PUBLIC_URL}/policy`} component={Policy} />
                                <Route path={`${process.env.PUBLIC_URL}/about_us`} component={AboutUs} />
                                <Route path={`${process.env.PUBLIC_URL}/class_tips`} component={OnlineClassTips} />
                                <Route path={`${process.env.PUBLIC_URL}/shop_tips`} component={OnlineShopTips} />
                                <Route path={`${process.env.PUBLIC_URL}/point_usage`} component={PointUsage} />
                                <Route path={`${process.env.PUBLIC_URL}/help_content`} component={HelpContent} />
                                <Route path={`${process.env.PUBLIC_URL}/ads_banner`} component={AdsBanner} />
                                <Route path={`${process.env.PUBLIC_URL}/footer_tone`} component={FooterTone} />
                                <Route path={`${process.env.PUBLIC_URL}/ads_pop_up`} component={AdsPopUp} />
                                <Route path={`${process.env.PUBLIC_URL}/app_share`} component={AppShare} />
                                <Route path={`${process.env.PUBLIC_URL}/change_log_mobile`} component={ChangeLogMobile} />
                                <Route path={`${process.env.PUBLIC_URL}/change_log_website`} component={ChangeLogWebsite} />
                                <Route path={`${process.env.PUBLIC_URL}/change_log_backend`} component={ChangeLogBackend} />
                                <Route path={`${process.env.PUBLIC_URL}/change_log_server`} component={ChangeLogServer} />
                                <Route path={`${process.env.PUBLIC_URL}/apk`} component={APK} />
                            </App>

                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


