import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import API from "../../../request/api";
import 'react-day-picker/lib/style.css';
// import Custom Componenets
import Breadcrumb from '../../common/breadcrumb.component';

import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            item: {
                name_kh: "",
                name_en: "",
                active_image: null,
                inactive_image: null
            },
            showAlert: false,
            btn_active_image: null,
            btn_inactive_image: null
        };
    }
    handleDayChange(day) {

        // this.state.user.created_at = new Date(day).toLocaleDateString("en-US");
        this.state.user.created_at = day;
        this.setState({});
    }

    toastSuccess() {
        toast.success("Action Successful !")
    }

    toastFail(text) {
        toast.error(text)
    }

    hideAlert = () => {
        this.setState({
            showAlert: false
        });
    }

    sweetAlert() {
        return <SweetAlert
            showCancel
            confirmBtnText="Yes, I do!"
            confirmBtnBsStyle={"warning"}
            type={"warning"}
            title="Are you sure?"
            onCancel={this.hideAlert}
            onConfirm={this.onConfirm}
        >
            This will create new category
    </SweetAlert>
    }

    onConfirm = async () => {
        this.hideAlert();
        
        let data = {
            name_kh: this.state.item.name_kh,
            name_en: this.state.item.name_en,
        }
        
        let result = await API.createReportType(data);

        if (result.result) {
            this.toastSuccess();
            this.props.history.goBack();
        } else {
            this.toastFail(result.message)
        }
    }

    onImageChange(event, type) {
        // Assuming only image
        if (type == 1)
            this.state.item.active_image = event.target.files[0]
        else
            this.state.item.inactive_image = event.target.files[0]
        this.setState({})
    }

    render() {
        return (
            <div>
                <Breadcrumb title="Report Type New" parent="Report" />
                {this.state.showAlert && this.sweetAlert()}
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>New Report Type</h5><span>
                                                {/* <img
                                                    src={this.state.user.profile_url}
                                                    className="img-100 rounded-circle"
                                                // style={{width: 300, height: 300}}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <Form className="theme-form">
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        English Name</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.item.name_en = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.item.name_en}
                                                            className="form-control" type="text" placeholder="English Name" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">
                                                        Khmer Name</Label>
                                                    <Col sm="9">
                                                        <Input
                                                            onChange={(e) => {
                                                                this.state.item.name_kh = e.target.value
                                                                this.setState({})
                                                            }}
                                                            value={this.state.item.name_kh}
                                                            className="form-control" type="text" placeholder="Khmer Name" />
                                                    </Col>
                                                </FormGroup>

                                            </Form>
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => this.setState({ showAlert: true })} color='primary'>Submit</Button>{' '}
                                            <Button onClick={() => this.props.history.goBack()} color='secondary'>Cancel</Button>{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Forms;
